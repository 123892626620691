import Layout from "./Layout";
import { Ts } from "../libs/js/utils";
import SectionHeader from "../components/SectionHeader";
import { useState } from "react";
import List from "../components/List";
import Button, { SIZES, STATUS } from "../components/Button";
import { useNavigate } from "react-router-dom";
import { Workshop } from "../libs/js/api";
import CustomerListItem from "../components/CustomerListItem";
import SvgIcon from "../components/SvgIcon";

const CustomerManagementPage = ({ }) => {
    // const [searchText, setSearchText] = useState("");

    const navigate = useNavigate();

    function Format(customer, onDelete, index) {
        return <CustomerListItem key={index} customer={customer} />
    }

    return (
        <>
            <Layout showHeader={false}>
                <SectionHeader title={Ts("Profilo - Gestione Clienti")} />
                <section>
                    <div className="box-shadow">
                        <p>{Ts("Gestione dei tuoi clienti")}</p>
                        {/* <div class="search-box">
                            <input value={searchText} type="search" placeholder={Ts("Ricerca clienti")} onChange={(e) => { setSearchText(e.currentTarget.value) }} />
                            <svg class="svg-icon search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" /></svg>
                        </div> */}
                        <ul>
                            <List api={(page, pageSize) => { return Workshop.GetCustomers(page, pageSize) }} format={Format} emptyPlaceholder={<>
                                <div style={{
                                    padding: '50px 10px'
                                }} className="text-center">{Ts("Ancora nessun cliente")}</div>
                            </>} />
                        </ul>
                    </div>
                </section>
                <div className="button-set fixed-bottom">
                    <Button label={<><SvgIcon id="add-user" /> {Ts("AGGIUNGI CLIENTE")}</>} onClick={() => { navigate('new') }} />
                </div>
            </Layout>
        </>
    )
}

export default CustomerManagementPage;