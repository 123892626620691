import "./Workshop.scss";
import { useEffect, useRef, useState } from "react";
import Button, { SIZES, STATUS } from "../components/Button";
import InputField from "../components/InputField";
import SectionHeader from "../components/SectionHeader";
import Layout from "./Layout";
import { GetThemeById, Ts, UpdateTheme } from "../libs/js/utils";
import { useUserContext } from "../components/UserContext";
import { Upload, Workshop } from "../libs/js/api";
import SvgIcon from "../components/SvgIcon";
import { useNotification } from "../components/NotificationContext";
import { NOTIFICATION_TYPES } from "../components/Notification";
import { useBrandContext } from "../components/BrandContext";

const WorkshopPage = () => {
    const { workshop, setWorkshop } = useUserContext();
    const { setBrand } = useBrandContext();
    const [logo, setLogo] = useState("");
    const [logoMin, setLogoMin] = useState("");
    const [themeId, setThemeId] = useState(0);

    const [businessName, setBusinessName] = useState("");
    const [businessNameError, setBusinessNameError] = useState("");

    const [vatNumber, setVatNumber] = useState("");
    const [vatNumberError, setVatNumberError] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [managerName, setManagerName] = useState("");
    const [managerNameError, setManagerNameError] = useState("");

    const [banner, setBanner] = useState("");
    const [bannerError, setBannerError] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const inputFile = useRef(null);
    const { addNotification } = useNotification();
    useEffect(() => {
        // TODO: get data logic
        setBusinessName(workshop?.business_name || "")
        setBanner(workshop?.display_name || "")
        setManagerName(workshop?.owner_name || "")
        setVatNumber(workshop?.vat_number || "")
        setEmail(workshop?.business_email || "")
        setThemeId(workshop?.theme_id || 0)
        setLogo(workshop?.logo_url || "")
        setLogoMin(workshop?.logo_min_url || "")
    }, [workshop])

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    const handleSubmit = async () => {
        if (businessName === "")
            return setBusinessNameError(Ts("Inserisci la ragione sociale"))
        if (vatNumber === "")
            return setVatNumberError(Ts("Inserisci il numero di partita IVA"))
        if (email === "")
            return setEmailError(Ts("Inserisci l'e-mail"))
        if (managerName === "")
            return setManagerNameError(Ts("Inserisci il nome del responsabile"))
        if (banner === "")
            return setBannerError(Ts("Inserisci il nome che verrà visualizzato"))

        try {
            setIsSubmitting(true);
            let logoUrl = logo;
            let logoMinUrl = logoMin;
            if (logo instanceof File) {
                const res = await Upload.Put(logo);
                logoUrl = res.data.url;
                logoMinUrl = logoUrl;
            }
            const res = await Workshop.Save(businessName, managerName, email, banner, logoUrl, logoMinUrl, themeId, "", vatNumber)
            console.log(res);
            addNotification(Ts("Salvato"), NOTIFICATION_TYPES.SUCCESS)
            setWorkshop(res.data.garage_data)
            setBrand(res.data.brand)
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }

    }

    function OnThemeChange(theme, index) {
        setThemeId(index + 1);
        UpdateTheme(index + 1);
    }

    function OnFile(e) {
        console.log(e);
        const files = e.currentTarget.files;
        const file = files[0];
        if (file) {
            const src = URL.createObjectURL(file);
            setLogo(file);
        }
    }

    return (
        <>
            <Layout showHeader={false}>
                <SectionHeader title={Ts("Profilo - Gestione Officina")} />
                <section>
                    <div className="box-shadow">
                        <div className="form-row">
                            <div className="logo-uploader">
                                {logo != "" &&
                                    <>
                                        <img src={logo instanceof File ? URL.createObjectURL(logo) : logo} />
                                        <div className="actions">
                                            {/* <div onClick={() => { }}>
                                                <SvgIcon width={20} height={20} id="edit" />
                                            </div> */}
                                            <div onClick={() => { inputFile.current.value = ""; setLogo(""); setLogoMin(""); }}>
                                                <SvgIcon width={20} height={20} id="delete" />
                                            </div>
                                        </div>
                                    </>
                                }
                                {logo == "" && <div onClick={() => { inputFile.current.click() }} className="placeholder"><SvgIcon id="add-photo" /> {Ts("Carica il logo")}</div>}
                                <input ref={inputFile} type="file" onChange={OnFile} style={{ display: 'none' }} />
                            </div>
                        </div>
                        <p>{Ts("Impostazioni generali del tuo account.")}</p>
                        <div className="form-row">
                            <div className="cc-input-field">
                                <label>{Ts("Colore sociale")} *</label>
                                <div className="palette">
                                    {Object.values(GetThemeById()).slice(1).map((theme, index) => (
                                        <div key={index} onClick={() => { OnThemeChange(theme, index) }} className={theme}>
                                            {index + 1 == themeId && <SvgIcon id="check" />}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Ragione sociale")} value={businessName} isRequired={true} error={businessNameError} onChange={e => handleInputChange(e.target.value, setBusinessName, setBusinessNameError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Partita IVA")} value={vatNumber} isRequired={true} error={vatNumberError} onChange={e => handleInputChange(e.target.value, setVatNumber, setVatNumberError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("E-mail")} value={email} isRequired={true} error={emailError} onChange={e => handleInputChange(e.target.value, setEmail, setEmailError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Nome Responsabile")} value={managerName} isRequired={true} error={managerNameError} onChange={e => handleInputChange(e.target.value, setManagerName, setManagerNameError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Insegna (Nome visualizzato)")} value={banner} isRequired={true} error={bannerError} onChange={e => handleInputChange(e.target.value, setBanner, setBannerError)} />
                        </div>
                    </div>
                </section>
                <div className="button-set fixed-bottom">
                    <Button label={Ts("Salva Modifiche")} onClick={() => { handleSubmit() }} size={SIZES.BIG} status={isSubmitting ? STATUS.WAITING : undefined} />
                </div>
            </Layout>
        </>
    )
}
export default WorkshopPage;