
window["MobileAppInterfaceEvents"] = {}

export function TriggerEvent(ID, data) {
    let obj = window["MobileAppInterfaceEvents"]
    let cEvent = new CustomEvent(ID, { detail: data })
    obj[ID] = cEvent
    document.dispatchEvent(cEvent)
}

//def need to be of class Deferred
export function InvokeAction(Action, oData, def) {
    var DefOp;
    if (def != null) {
        DefOp = def;
    } else {
        DefOp = new Deferred()
    }
    var DefWrapperID = DeferredWrapperForNative(DefOp);
    try {
        var __InvokeNativeAction = window["__InvokeNativeAction"] || window.parent["__InvokeNativeAction"];
        if (typeof __InvokeNativeAction == "function") {
            let data = null;
            if (typeof oData === "string")
                data = oData;
            if (typeof oData === "object")
                data = JSON.stringify(oData);
            __InvokeNativeAction(JSON.stringify({ action: Action, data: data, defWrapperID: DefWrapperID }));
        } else {
            setTimeout(function () {
                InvokeAction(Action, oData, def)
            }, 1000);
        }

    }
    catch (Ex) { DefOp.reject("__InvokeNativeAction not defined"); }
    return DefOp;
}

export function DeferredWrapperForNative(def) {
    var ID = GetRandomString(10);
    window['MobileAppInterface_' + ID] = def;

    document.addEventListener(ID, function (event) {
        var id = 'MobileAppInterface_' + event.type;
        def = window[id];
        try {
            if (event.detail == '-1')
                def.reject(event.detail);
            else
                def.resolve(event.detail);

            let obj = window["MobileAppInterfaceEvents"]
            document.removeEventListener(ID, obj[ID])
            window[id] = null
        } catch (Ex) {
            console.log('Deffered Native Failed ' + Ex);
        }
    });

    return ID;
}

export function IsAppIOS() {
    if (window.webkit && window.webkit.messageHandlers && typeof window.webkit.messageHandlers.__InvokeNativeAction === 'object')
        return true;

    return false;
}

export function IsApp() {
    return (typeof (window.webkit) === "object" || typeof (__InvokeNativeAction) === "function" || typeof (window.parent.__InvokeNativeAction) === "function" || (typeof (MobileAppInterfaceWebBridge) === "object")); //  && typeof (MobileAppInterfaceWebBridge.__InvokeNativeAction) === "function"
}

export function GetVersion(DefOp) {
    if (DefOp == null) {
        DefOp = new Deferred()
    }
    var currentVersion = window["MobileAppInterface_CurrentVersion"];

    if (currentVersion == null) {
        InvokeAction("GetVersion", '')
            .done((version) => {
                DefOp.resolve(version);
                window["MobileAppInterface_CurrentVersion"] = version
            })
            .fail(() => {
                DefOp.reject();
            });
    } else {
        DefOp.resolve(currentVersion)
    }
    return DefOp;
}

export function GetRandomString(legth = 8) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < legth; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

function Back() {
    window.history.back();
}

export class Deferred {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

const MobileAppInterface = {
    TriggerEvent,
    InvokeAction,
    DeferredWrapperForNative,
    IsApp,
    GetVersion,
    Back
};

export default MobileAppInterface;
window.MobileAppInterface = MobileAppInterface;

if (IsApp() == false)
    window.APP_DEBUG = true