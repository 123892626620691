import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "../libs/js/api";
import InputField from "../components/InputField";
import Button, { LENGTHS, SIZES, STATUS, TYPES } from "../components/Button";
import SvgIcon from "../components/SvgIcon";
import { useUserContext } from "../components/UserContext";
import Layout from "./Layout";
import { useBrandContext } from "../components/BrandContext";
import { useNotification } from "../components/NotificationContext";
import { NOTIFICATION_TYPES } from "../components/Notification";
import { Ts } from "../libs/js/utils";

const LoginPage = ({ onLogin }) => {
    const { setIsLoggedIn, setUser, setWorkshop, setUserEmail } = useUserContext();
    const { setBrand } = useBrandContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({ email: '', password: '' });
    const navigate = useNavigate();
    const { addNotification } = useNotification();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: ''
        }))
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            password: ''
        }))
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter')
            handleLogin();
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { email: '', password: '' };

        if (!email.trim()) {
            newErrors.email = Ts("Inserisci la e-mail");
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = Ts('E-mail non valida');
            valid = false;
        }

        if (!password.trim()) {
            newErrors.password = Ts("Inserisci la password");
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleLogin = async () => {
        if (validateForm()) {
            try {
                setIsLoading(true);
                const res = await Auth.Login(email, password);
                console.log(res.data)
                setIsLoggedIn(true)
                setBrand(res.data.brand)
                setUser(res.data.user_data)
                setUserEmail(res.data.user.email)
                setWorkshop(res.data.garage_data)
                onLogin();
                localStorage.setItem('authToken', res.data.auth_token)

                navigate('/');
            } catch (error) {
                console.error(error);
                if (error?.response?.status == 500) {
                    addNotification(Ts("Account non trovato"), NOTIFICATION_TYPES.ERROR)
                } else if (error?.response?.status == 403) {
                    addNotification(Ts("Indirizzo e-mail non verificato"), NOTIFICATION_TYPES.ERROR)
                } else {
                    addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR)
                }
            }
            setIsLoading(false);
        }

    };

    return (
        <Layout>
            <section>
                <div className="box-shadow brd-rounded">
                    <h2 className="title-1 text-center">{Ts("Accedi")}</h2>
                    <div className="form-row mt-2">
                        <InputField id="INPUT-EMAIL" type="email" value={email} label={Ts("E-mail")} placeholder={Ts("Inserisci la mail")} onChange={handleEmailChange} onKeyUp={handleKeyUp} error={errors.email} />
                    </div>
                    <div className="form-row password-off">
                        <InputField id="INPUT-PASSWORD" type="password" value={password} label={Ts("Password")} placeholder={Ts("Inserisci la password")} onChange={handlePasswordChange} onKeyUp={handleKeyUp} error={errors.password} />
                    </div>

                    <div className="button-set mt-0">
                        <Button label={Ts("ACCEDI")} onClick={handleLogin} size={SIZES.BIG} length={LENGTHS.FULLWIDTH} status={isLoading && STATUS.WAITING} />
                    </div>
                    <div className="text-center mb-3">
                        <Link to="/password-recovery" className="plain-link small">{Ts("Recupero password")}</Link>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {Ts("Non hai un account")}
                    <div className="button-set mt-2">
                        <Link to="/register" className="plain-link">{Ts("Registrati >>")}</Link>
                        {/* <Button label={Ts("REGISTRATI")} onClick={() => { navigate('/register') }} type={TYPES.OUTLINE} size={SIZES.BIG} length={LENGTHS.FULLWIDTH}></Button> */}
                    </div>

                </div>

            </section>
        </Layout>
    );
};

export default LoginPage;