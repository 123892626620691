var CountriesByISOCode =
{
    "ABW": {
        "P": "297",
        "N": "{%T|Aruba|%}",
    },
    "AFG": {
        "P": "93",
        "N": "{%T|Afghanistan|%}",
        "TrunkPrefix": "0",
    },
    "AGO": {
        "P": "244",
        "N": "{%T|Angola|%}"
    },
    "AIA": {
        "P": "1",
        "N": "{%T|Anguilla|%}"
    },
    "ALB": {
        "P": "355",
        "N": "{%T|Albania|%}",
        "TrunkPrefix": "0",
    },
    "AND": {
        "P": "376",
        "N": "{%T|Andorra|%}"
    },
    "ARE": {
        "P": "971",
        "N": "{%T|Emirati Arabi Uniti|%}"
    },
    "ARG": {
        "P": "54",
        "N": "{%T|Argentina|%}",
        "TrunkPrefix": "0",
    },
    "ARM": {
        "P": "374",
        "N": "{%T|Armenia|%}"
    },
    "ASM": {
        "P": "1",
        "N": "{%T|Samoa Americane|%}"
    },
    "ATG": {
        "P": "1",
        "N": "{%T|Antigua e Barbuda|%}"
    },
    "AUS": {
        "P": "61",
        "N": "{%T|Australia|%}",
        "TrunkPrefix": "0",
    },
    "AUT": {
        "P": "43",
        "N": "{%T|Austria|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "AZE": {
        "P": "994",
        "N": "{%T|Azerbaigian|%}",
        "TrunkPrefix": "8",
    },
    "BDI": {
        "P": "257",
        "N": "{%T|Burundi|%}"
    },
    "BEL": {
        "P": "32",
        "N": "{%T|Belgio|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "BEN": {
        "P": "229",
        "N": "{%T|Benin|%}"
    },
    "BFA": {
        "P": "226",
        "N": "{%T|Burkina Faso|%}"
    },
    "BGD": {
        "P": "880",
        "N": "{%T|Bangladesh|%}",
        "TrunkPrefix": "0",
    },
    "BGR": {
        "P": "359",
        "N": "{%T|Bulgaria|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "BHR": {
        "P": "973",
        "N": "{%T|Bahrein|%}"
    },
    "BHS": {
        "P": "1",
        "N": "{%T|Bahamas|%}"
    },
    "BIH": {
        "P": "387",
        "N": "{%T|Bosnia ed Erzegovina|%}",
        "TrunkPrefix": "0",
    },
    "BLM": {
        "P": "590",
        "N": "{%T|San Bartolomeo|%}"
    },
    "BLR": {
        "P": "375",
        "N": "{%T|Bielorussia|%}",
        "TrunkPrefix": "8",
    },
    "BLZ": {
        "P": "501",
        "N": "{%T|Belize|%}"
    },
    "BMU": {
        "P": "1",
        "N": "{%T|Bermuda|%}"
    },
    "BOL": {
        "P": "591",
        "N": "{%T|Bolivia|%}",
        "TrunkPrefix": "0",
    },
    "BRA": {
        "P": "55",
        "N": "{%T|Brasile|%}",
        "TrunkPrefix": "0",
    },
    "BRB": {
        "P": "1",
        "N": "{%T|Barbados|%}"
    },
    "BRN": {
        "P": "673",
        "N": "{%T|Brunei|%}"
    },
    "BTN": {
        "P": "975",
        "N": "{%T|Bhutan|%}"
    },
    "BWA": {
        "P": "267",
        "N": "{%T|Botswana|%}"
    },
    "CAF": {
        "P": "236",
        "N": "{%T|Repubblica Centrafricana|%}"
    },
    "CAN": {
        "P": "1",
        "N": "{%T|Canada|%}"
    },
    "CCK": {
        "P": "61",
        "N": "{%T|Isole Cocos|%}"
    },
    "CHE": {
        "P": "41",
        "N": "{%T|Svizzera|%}",
        "TrunkPrefix": "0",
    },
    "CHL": {
        "P": "56",
        "N": "{%T|Cile|%}"
    },
    "CHN": {
        "P": "86",
        "N": "{%T|Cina|%}",
        "TrunkPrefix": "0",
    },
    "CIV": {
        "P": "225",
        "N": "{%T|Costa D'Avorio|%}"
    },
    "CMR": {
        "P": "237",
        "N": "{%T|Camerun|%}"
    },
    "COD": {
        "P": "243",
        "N": "{%T|Repubblica Democratica del Congo|%}"
    },
    "COG": {
        "P": "242",
        "N": "{%T|Repubblica del Congo|%}"
    },
    "COK": {
        "P": "682",
        "N": "{%T|Isole Cook|%}"
    },
    "COL": {
        "P": "57",
        "N": "{%T|Colombia|%}"
    },
    "COM": {
        "P": "269",
        "N": "{%T|Unione delle Comore|%}"
    },
    "CPV": {
        "P": "238",
        "N": "{%T|Capo Verde|%}"
    },
    "CRI": {
        "P": "506",
        "N": "{%T|Costa Rica|%}"
    },
    "CUB": {
        "P": "53",
        "N": "{%T|Cuba|%}"
    },
    "CUW": {
        "P": "599",
        "N": "{%T|Curaçao|%}"
    },
    "CYM": {
        "P": "1",
        "N": "{%T|Isole Cayman|%}"
    },
    "CYP": {
        "P": "357",
        "N": "{%T|Cipro|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "CZE": {
        "P": "420",
        "N": "{%T|Repubblica Ceca|%}",
        "EU": true,
    },
    "DEU": {
        "P": "49",
        "N": "{%T|Germania|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "DJI": {
        "P": "253",
        "N": "{%T|Gibuti|%}"
    },
    "DMA": {
        "P": "1",
        "N": "{%T|Dominica|%}"
    },
    "DNK": {
        "P": "45",
        "N": "{%T|Danimarca|%}",
        "EU": true,
    },
    "DOM": {
        "P": "1",
        "N": "{%T|Repubblica Dominicana|%}"
    },
    "DZA": {
        "P": "213",
        "N": "{%T|Algeria|%}"
    },
    "ECU": {
        "P": "593",
        "N": "{%T|Ecuador|%}"
    },
    "EGY": {
        "P": "20",
        "N": "{%T|Egitto|%}",
        "TrunkPrefix": "0",
    },
    "ERI": {
        "P": "291",
        "N": "{%T|Eritrea|%}"
    },
    "ESP": {
        "P": "34",
        "N": "{%T|Spagna|%}",
        "EU": true,
    },
    "EST": {
        "P": "372",
        "N": "{%T|Estonia|%}",
        "EU": true,
    },
    "ETH": {
        "P": "251",
        "N": "{%T|Etiopia|%}"
    },
    "FIN": {
        "P": "358",
        "N": "{%T|Finlandia|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "FJI": {
        "P": "679",
        "N": "{%T|Figi|%}"
    },
    "FLK": {
        "P": "500",
        "N": "{%T|Isole Falkland|%}"
    },
    "FRA": {
        "P": "33",
        "N": "{%T|Francia|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "FRO": {
        "P": "298",
        "N": "{%T|Isole Faroe|%}"
    },
    "FSM": {
        "P": "691",
        "N": "{%T|Micronesia|%}"
    },
    "GAB": {
        "P": "241",
        "N": "{%T|Gabon|%}"
    },
    "GBR": {
        "P": "44",
        "N": "{%T|Regno Unito|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "GEO": {
        "P": "995",
        "N": "{%T|Georgia|%}",
        "TrunkPrefix": "0",
    },
    "GHA": {
        "P": "233",
        "N": "{%T|Ghana|%}"
    },
    "GIB": {
        "P": "350",
        "N": "{%T|Gibilterra|%}"
    },
    "GIN": {
        "P": "224",
        "N": "{%T|Guinea|%}"
    },
    "GLP": {
        "P": "590",
        "N": "{%T|Guadalupa|%}"
    },
    "GMB": {
        "P": "220",
        "N": "{%T|Gambia|%}"
    },
    "GNB": {
        "P": "245",
        "N": "{%T|Guinea Bissau|%}"
    },
    "GNQ": {
        "P": "240",
        "N": "{%T|Guinea Equatoriale|%}"
    },
    "GRC": {
        "P": "30",
        "N": "{%T|Grecia|%}",
        "EU": true,
    },
    "GRD": {
        "P": "1",
        "N": "{%T|Grenada|%}"
    },
    "GRL": {
        "P": "299",
        "N": "{%T|Groenlandia|%}"
    },
    "GTM": {
        "P": "502",
        "N": "{%T|Guatemala|%}"
    },
    "GUY": {
        "P": "592",
        "N": "{%T|Guyana|%}"
    },
    "HKG": {
        "P": "852",
        "N": "{%T|Hong Kong|%}"
    },
    "HND": {
        "P": "504",
        "N": "{%T|Honduras|%}"
    },
    "HRV": {
        "P": "385",
        "N": "{%T|Croazia|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "HTI": {
        "P": "509",
        "N": "{%T|Haiti|%}"
    },
    "HUN": {
        "P": "36",
        "N": "{%T|Ungheria|%}",
        "TrunkPrefix": "06",
        "EU": true,
    },
    "IDN": {
        "P": "62",
        "N": "{%T|Indonesia|%}",
        "TrunkPrefix": "0",
    },
    "IND": {
        "P": "91",
        "N": "{%T|India|%}",
        "TrunkPrefix": "0",
    },
    "IOT": {
        "P": "246",
        "N": "{%T|Oceano Indiano Britannico|%}"
    },
    "IRL": {
        "P": "353",
        "N": "{%T|Irlanda|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "IRN": {
        "P": "98",
        "N": "{%T|Iran|%}",
        "TrunkPrefix": "0",
    },
    "IRQ": {
        "P": "964",
        "N": "{%T|Iraq|%}"
    },
    "ISL": {
        "P": "354",
        "N": "{%T|Islanda|%}"
    },
    "ISR": {
        "P": "972",
        "N": "{%T|Israele|%}",
        "TrunkPrefix": "0",
    },
    "ITA": {
        "P": "39",
        "N": "{%T|Italia|%}",
        "EU": true,
    },
    "JAM": {
        "P": "1",
        "N": "{%T|Giamaica|%}"
    },
    "JOR": {
        "P": "962",
        "N": "{%T|Giordania|%}",
        "TrunkPrefix": "0",
    },
    "JPN": {
        "P": "81",
        "N": "{%T|Giappone|%}",
        "TrunkPrefix": "0",
    },
    "KAZ": {
        "P": "7",
        "N": "{%T|Kazakistan|%}",
        "TrunkPrefix": "8",
    },
    "KEN": {
        "P": "254",
        "N": "{%T|Kenya|%}",
        "TrunkPrefix": "0",
    },
    "KGZ": {
        "P": "996",
        "N": "{%T|Kirghizistan|%}"
    },
    "KHM": {
        "P": "855",
        "N": "{%T|Cambogia|%}",
        "TrunkPrefix": "0",
    },
    "KIR": {
        "P": "686",
        "N": "{%T|Kiribati|%}"
    },
    "KNA": {
        "P": "1",
        "N": "{%T|Saint Kitts and Nevis|%}"
    },
    "KOR": {
        "P": "82",
        "N": "{%T|Corea del Sud|%}",
        "TrunkPrefix": "0",
    },
    "KWT": {
        "P": "965",
        "N": "{%T|Kuwait|%}"
    },
    "LAO": {
        "P": "856",
        "N": "{%T|Laos|%}",
        "TrunkPrefix": "0",
    },
    "LBN": {
        "P": "961",
        "N": "{%T|Libano|%}"
    },
    "LBR": {
        "P": "231",
        "N": "{%T|Liberia|%}"
    },
    "LBY": {
        "P": "218",
        "N": "{%T|Libia|%}"
    },
    "LCA": {
        "P": "1",
        "N": "{%T|Santa Lucia|%}"
    },
    "LIE": {
        "P": "423",
        "N": "{%T|Liechtenstein|%}"
    },
    "LKA": {
        "P": "94",
        "N": "{%T|Sri Lanka|%}",
        "TrunkPrefix": "0",
    },
    "LSO": {
        "P": "266",
        "N": "{%T|Lesotho|%}"
    },
    "LTU": {
        "P": "370",
        "N": "{%T|Lituania|%}",
        "TrunkPrefix": "8",
        "EU": true,
    },
    "LUX": {
        "P": "352",
        "N": "{%T|Lussemburgo|%}",
        "EU": true,
    },
    "LVA": {
        "P": "371",
        "N": "{%T|Lettonia|%}",
        "EU": true,
    },
    "MAC": {
        "P": "853",
        "N": "{%T|Macao|%}"
    },
    "MAR": {
        "P": "212",
        "N": "{%T|Marocco|%}",
        "TrunkPrefix": "0",
    },
    "MCO": {
        "P": "377",
        "N": "{%T|Monaco|%}"
    },
    "MDA": {
        "P": "373",
        "N": "{%T|Moldavia|%}",
        "TrunkPrefix": "0",
    },
    "MDG": {
        "P": "261",
        "N": "{%T|Madagascar|%}"
    },
    "MDV": {
        "P": "960",
        "N": "{%T|Maldive|%}"
    },
    "MEX": {
        "P": "52",
        "N": "{%T|Messico|%}",
        "TrunkPrefix": "01",
    },
    "MHL": {
        "P": "692",
        "N": "{%T|Isole Marshall|%}"
    },
    "MKD": {
        "P": "389",
        "N": "{%T|Macedonia|%}",
        "TrunkPrefix": "0",
    },
    "MLI": {
        "P": "223",
        "N": "{%T|Mali|%}"
    },
    "MLT": {
        "P": "356",
        "N": "{%T|Malta|%}",
        "EU": true,
    },
    "MMR": {
        "P": "95",
        "N": "{%T|Myanmar|%}"
    },
    "MNE": {
        "P": "382",
        "N": "{%T|Montenegro|%}",
        "TrunkPrefix": "0",
    },
    "MNG": {
        "P": "976",
        "N": "{%T|Mongolia|%}",
        "TrunkPrefix": ["01", "02"],
    },
    "MOZ": {
        "P": "258",
        "N": "{%T|Mozambico|%}"
    },
    "MRT": {
        "P": "222",
        "N": "{%T|Mauritania|%}"
    },
    "MSR": {
        "P": "1",
        "N": "{%T|Montserrat|%}"
    },
    "MTQ": {
        "P": "596",
        "N": "{%T|Martinica|%}"
    },
    "MUS": {
        "P": "230",
        "N": "{%T|Mauritius|%}"
    },
    "MWI": {
        "P": "265",
        "N": "{%T|Malawi|%}"
    },
    "MYS": {
        "P": "60",
        "N": "{%T|Malesia|%}",
        "TrunkPrefix": "0",
    },
    "MYT": {
        "P": "262",
        "N": "{%T|Mayotte|%}"
    },
    "NAM": {
        "P": "264",
        "N": "{%T|Namibia|%}"
    },
    "NCL": {
        "P": "687",
        "N": "{%T|Nuova Caledonia|%}"
    },
    "NER": {
        "P": "227",
        "N": "{%T|Niger|%}"
    },
    "NFK": {
        "P": "672",
        "N": "{%T|Isola Norfolk|%}"
    },
    "NGA": {
        "P": "234",
        "N": "{%T|Nigeria|%}",
        "TrunkPrefix": "0",
    },
    "NIC": {
        "P": "505",
        "N": "{%T|Nicaragua|%}"
    },
    "NIU": {
        "P": "683",
        "N": "{%T|Niue|%}"
    },
    "NLD": {
        "P": "31",
        "N": "{%T|Paesi Bassi|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "NOR": {
        "P": "47",
        "N": "{%T|Norvegia|%}"
    },
    "NPL": {
        "P": "977",
        "N": "{%T|Nepal|%}",
        "TrunkPrefix": "0",
    },
    "NRU": {
        "P": "674",
        "N": "{%T|Nauru|%}"
    },
    "NZL": {
        "P": "64",
        "N": "{%T|Nuova Zelanda|%}",
        "TrunkPrefix": "0",
    },
    "OMN": {
        "P": "968",
        "N": "{%T|Oman|%}"
    },
    "PAK": {
        "P": "92",
        "N": "{%T|Pakistan|%}",
        "TrunkPrefix": "0",
    },
    "PAN": {
        "P": "507",
        "N": "{%T|Panama|%}"
    },
    "PCN": {
        "P": "64",
        "N": "{%T|Isole Pitcairn|%}"
    },
    "PER": {
        "P": "51",
        "N": "{%T|Peru|%}",
        "TrunkPrefix": "0",
    },
    "PHL": {
        "P": "63",
        "N": "{%T|Filippine|%}",
        "TrunkPrefix": "0",
    },
    "PLW": {
        "P": "680",
        "N": "{%T|Palau|%}"
    },
    "PNG": {
        "P": "675",
        "N": "{%T|Papua Nuova Guinea|%}"
    },
    "POL": {
        "P": "48",
        "N": "{%T|Polonia|%}",
        "EU": true,
    },
    "PRI": {
        "P": "1",
        "N": "{%T|Porto Rico|%}",
        "EU": true,
    },
    "PRK": {
        "P": "850",
        "N": "{%T|Corea del Nord|%}",
        "TrunkPrefix": "0",
    },
    "PRT": {
        "P": "351",
        "N": "{%T|Portogallo|%}",
        "EU": true,
    },
    "PRY": {
        "P": "595",
        "N": "{%T|Paraguay|%}"
    },
    "PSE": {
        "P": "970",
        "N": "{%T|Palestina|%}"
    },
    "PYF": {
        "P": "689",
        "N": "{%T|Polinesia Francese|%}"
    },
    "QAT": {
        "P": "974",
        "N": "{%T|Qatar|%}"
    },
    "ROU": {
        "P": "40",
        "N": "{%T|Romania|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "RUS": {
        "P": "7",
        "N": "{%T|Russia|%}",
        "TrunkPrefix": "8",
    },
    "RWA": {
        "P": "250",
        "N": "{%T|Ruanda|%}",
        "TrunkPrefix": "0",
    },
    "SAU": {
        "P": "966",
        "N": "{%T|Arabia Saudita|%}"
    },
    "SDN": {
        "P": "249",
        "N": "{%T|Sudan|%}"
    },
    "SEN": {
        "P": "221",
        "N": "{%T|Senegal|%}"
    },
    "SGP": {
        "P": "65",
        "N": "{%T|Singapore|%}"
    },
    "SGS": {
        "P": "500",
        "N": "{%T|Georgia del Sud e Isole Sandwich Australi|%}"
    },
    "SHN": {
        "P": "290",
        "N": "{%T|Sant Elena e Tristan|%}"
    },
    "SLB": {
        "P": "677",
        "N": "{%T|Isole Salomone|%}"
    },
    "SLE": {
        "P": "232",
        "N": "{%T|Sierra Leone|%}"
    },
    "SLV": {
        "P": "503",
        "N": "{%T|El Salvador|%}"
    },
    "SMR": {
        "P": "378",
        "N": "{%T|San Marino|%}"
    },
    "SOM": {
        "P": "252",
        "N": "{%T|Somalia|%}"
    },
    "SPM": {
        "P": "508",
        "N": "{%T|Saint Pierre e Miquelon|%}"
    },
    "SRB": {
        "P": "381",
        "N": "{%T|Serbia|%}",
        "TrunkPrefix": "0",
    },
    "SSD": {
        "P": "211",
        "N": "{%T|Sud Sudan|%}"
    },
    "STP": {
        "P": "239",
        "N": "{%T|Sao Tome e Principe|%}"
    },
    "SUR": {
        "P": "597",
        "N": "{%T|Suriname|%}"
    },
    "SVK": {
        "P": "421",
        "N": "{%T|Slovacchia|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "SVN": {
        "P": "386",
        "N": "{%T|Slovenia|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "SWE": {
        "P": "46",
        "N": "{%T|Svezia|%}",
        "TrunkPrefix": "0",
        "EU": true,
    },
    "SWZ": {
        "P": "268",
        "N": "{%T|Swaziland|%}"
    },
    "SXM": {
        "P": "1",
        "N": "{%T|Sint Maarten|%}"
    },
    "SYC": {
        "P": "248",
        "N": "{%T|Seychelles|%}"
    },
    "SYR": {
        "P": "963",
        "N": "{%T|Siria|%}"
    },
    "TCA": {
        "P": "1",
        "N": "{%T|Turks e Caicos|%}"
    },
    "TCD": {
        "P": "235",
        "N": "{%T|Ciad|%}"
    },
    "TGO": {
        "P": "228",
        "N": "{%T|Togo|%}"
    },
    "THA": {
        "P": "66",
        "N": "{%T|Tailandia|%}",
        "TrunkPrefix": "0",
    },
    "TJK": {
        "P": "992",
        "N": "{%T|Tagikistan|%}"
    },
    "TKL": {
        "P": "690",
        "N": "{%T|Tokelau|%}"
    },
    "TKM": {
        "P": "993",
        "N": "{%T|Turkmenistan|%}",
        "TrunkPrefix": "8",
    },
    "TLS": {
        "P": "670",
        "N": "{%T|Timor Est|%}"
    },
    "TON": {
        "P": "676",
        "N": "{%T|Tonga|%}"
    },
    "TTO": {
        "P": "1",
        "N": "{%T|Trinidad e Tobago|%}"
    },
    "TUN": {
        "P": "216",
        "N": "{%T|Tunisia|%}"
    },
    "TUR": {
        "P": "90",
        "N": "{%T|Turchia|%}",
        "TrunkPrefix": "0",
    },
    "TUV": {
        "P": "688",
        "N": "{%T|Tuvalu|%}"
    },
    "TWN": {
        "P": "886",
        "N": "{%T|Taiwan|%}",
        "TrunkPrefix": "0",
    },
    "TZA": {
        "P": "255",
        "N": "{%T|Tanzania|%}",
        "TrunkPrefix": "0",
    },
    "UGA": {
        "P": "256",
        "N": "{%T|Uganda|%}"
    },
    "UKR": {
        "P": "380",
        "N": "{%T|Ucraina|%}",
        "TrunkPrefix": "0",
    },
    "UNK": {
        "P": "383",
        "N": "{%T|Kosovo|%}"
    },
    "URY": {
        "P": "598",
        "N": "{%T|Uruguay|%}"
    },
    "USA": {
        "P": "1",
        "N": "{%T|Stati Uniti d'America|%}"
    },
    "UZB": {
        "P": "998",
        "N": "{%T|Uzbekistan|%}",
        "TrunkPrefix": "8",
    },
    "VAT": {
        "P": "379",
        "N": "{%T|Città del Vaticano|%}"
    },
    "VCT": {
        "P": "1",
        "N": "{%T|Saint Vincent and the Grenadines|%}"
    },
    "VEN": {
        "P": "58",
        "N": "{%T|Venezuela|%}",
        "TrunkPrefix": "0",
    },
    "VGB": {
        "P": "1",
        "N": "{%T|Isole Vergini Britanniche|%}"
    },
    "VNM": {
        "P": "84",
        "N": "{%T|Vietnam|%}",
        "TrunkPrefix": "0",
    },
    "VUT": {
        "P": "678",
        "N": "{%T|Vanuatu|%}"
    },
    "WLF": {
        "P": "681",
        "N": "{%T|Wallis e Futuna|%}"
    },
    "WSM": {
        "P": "685",
        "N": "{%T|Samoa|%}"
    },
    "YEM": {
        "P": "967",
        "N": "{%T|Yemen|%}"
    },
    "ZAF": {
        "P": "27",
        "N": "{%T|Sud Africa|%}",
        "TrunkPrefix": "0",
    },
    "ZMB": {
        "P": "260",
        "N": "{%T|Zambia|%}"
    },
    "ZWE": {
        "P": "263",
        "N": "{%T|Zimbabwe|%}"
    }
};

export default CountriesByISOCode;