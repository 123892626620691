const SvgIcon = ({ id, width, height }) => {
    return (
        <>
            <svg className="svg-icon" style={{
                width: width + 'px',
                height: height + 'px'
            }}>
                <use xlinkHref={`#icon-${id}`} />
            </svg>
        </>
    )
}

export default SvgIcon;