import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import SectionHeader from "../components/SectionHeader";
import { Logout, Ts } from "../libs/js/utils";
import Layout from "./Layout";
import SvgIcon from "../components/SvgIcon";
import { useUserContext } from "../components/UserContext";

const ProfilePage = () => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const { workshop } = useUserContext();
    const navigate = useNavigate();

    const LogOut = () => {
        setShowLogoutModal(false)
        Logout();
        navigate(0);
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title={Ts("Profilo")} />
            <section>
                <div className="box-shadow profile-menu">
                    <ul>
                        {workshop ?
                            <li onClick={() => { navigate("/workshop") }}>
                                <div className="icon-menu">
                                    <SvgIcon id="user" />
                                </div>
                                <div className="copy-menu">
                                    <strong>{Ts("Gestione officina")}</strong>
                                    <p className="small-text">{Ts("Dati dell'officina")}</p>
                                </div>
                                <SvgIcon id="next" />
                            </li> :
                            <li onClick={() => { navigate("/account") }}>
                                <div className="icon-menu">
                                    <SvgIcon id="user" />
                                </div>
                                <div className="copy-menu">
                                    <strong>{Ts("Gestione account")}</strong>
                                    <p className="small-text">{Ts("Dati del tuo account")}</p>
                                </div>
                                <SvgIcon id="next" />
                            </li>
                        }
                        {/* <li onClick={() => { navigate("/billing-address") }}>
                            <div className="icon-menu">
                                <SvgIcon id="car" />
                            </div>
                            <div className="copy-menu">
                                <strong>{Ts("Gestione indirizzi")}</strong>
                                <p className="small-text">{Ts("Indirizzo di fatturazione")}</p>
                            </div>
                            <SvgIcon id="next" />
                        </li> */}
                        {/* <li onClick={() => { navigate("/orders") }}>
                            <div className="icon-menu">
                                <SvgIcon id="car" />
                            </div>
                            <div className="copy-menu">
                                <strong>Ordini</strong>
                                <p className="small-text">I tuoi pagamenti</p>
                            </div>
                            <SvgIcon id="next" />
                        </li> */}
                        {/* <li>
                            <div className="icon-menu">
                                <SvgIcon id="car" />
                            </div>
                            <div className="copy-menu">
                                <strong>Affiliazioni</strong>
                                <p className="small-text">Invita nuovi utenti in piattaforma</p>
                            </div>
                            <SvgIcon id="next" />
                        </li> */}
                        <li onClick={() => { setShowLogoutModal(true) }}>
                            <div className="icon-menu">
                                <SvgIcon id="logout" />
                            </div>
                            <div className="copy-menu">
                                <strong>{Ts("Logout")}</strong>
                                <p className="small-text">{Ts("Chiude la sessione in questo dispositivo")}</p>
                            </div>
                            <SvgIcon id="next" />
                        </li>
                    </ul>

                    {/* <div className="go-pro-bubble">
                        <h3 className="title-2">Sei un'officina?</h3>
                        <button className="btn-button">
                            <span>Attiva CronoCar Pro</span>
                        </button>
                        <p className="small-text">
                            CronoCar Pro è pensata per Officine e Professionisti per aiutare nella gestione e nel matenimento dei propri veicoli
                        </p>
                    </div> */}
                </div>
            </section>
            <Modal onClose={() => { setShowLogoutModal(false) }} onConfirm={() => { LogOut() }} isOpen={showLogoutModal} title={Ts("Sei sicuro di voler uscire?")} />
        </Layout>
    )

}

export default ProfilePage;