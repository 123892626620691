import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button, { LENGTHS, SIZES, STATUS } from "../components/Button";
import SvgIcon from "../components/SvgIcon";
import InputField from "../components/InputField";
import { NOTIFICATION_TYPES } from "../components/Notification";
import { useNotification } from "../components/NotificationContext";
import { Auth } from "../libs/js/api";
import { Ts } from "../libs/js/utils";
import Layout from "./Layout";

const PasswordResetPage = () => {
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passworConfirmdError, setPasswordConfirmError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchParams] = useSearchParams();
    const { addNotification } = useNotification();

    const token = searchParams.get("token");

    const navigate = useNavigate();

    const handlePasswordChange = e => {
        setPassword(e.currentTarget.value);
    }

    const handlePasswordConfirmChange = e => {
        setPasswordConfirm(e.currentTarget.value);
    }

    const handleKeyUp = () => {

    }

    const handleSubmit = async () => {
        setPasswordError("");
        setPasswordConfirmError("");
        if (!password.trim()) {
            setPasswordError(Ts("Inserisci la password"));
            return;
        } else if (password.length < 8) {
            setPasswordError(Ts("La password deve avere almeno 8 caratteri"));
            return;
        } else if (!/[A-Z]/.test(password)) {
            setPasswordError(Ts("La password deve avere almeno una lettera maiuscola"));
            return;
        } else if (!/[a-z]/.test(password)) {
            setPasswordError(Ts("La password deve avere almeno una lettera minuscola"));
            return;
        } else if (!/[\d]/.test(password)) {
            setPasswordError(Ts("La password deve avere almeno un numero"));
            return;
        } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-]/.test(password)) {
            setPasswordError(Ts("La password deve avere almeno uno tra i caratteri !@#$%^&*()_+{}\[\]:;<>,.?~\\/\-"));
            return;
        }

        if (!passwordConfirm.trim()) {
            setPasswordConfirmError(Ts("Re-inserisci la password"));
            return;
        } else if (password != passwordConfirm) {
            setPasswordConfirmError(Ts("La password non è uguale"));
            return;
        }

        setIsSubmitting(true)
        try {
            await Auth.PasswordReset(token, password)
            navigate('/login');
            addNotification(Ts("Password reimpostata con successo"), NOTIFICATION_TYPES.SUCCESS);
        } catch (error) {
            console.error(error);
            addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR);
        }
        setIsSubmitting(false)

    }

    return (
        <>
            <Layout>
                <section>
                    <div className="box-shadow brd-rounded">
                    <h2 className="title-1 text-center">{Ts("Re-imposta password")}</h2>
                        <div className="form-row password-off">
                            <InputField id="INPUT-PASSWORD" type="password" value={password} label={
                                <>
                                    {Ts("Password")}
                                    <div className="tooltip-help">
                                        <div>{`${Ts("Almeno 8 caratteri")}`}</div>
                                        <div>{`${Ts("Almeno una lettera maiuscola")}`}</div>
                                        <div>{`${Ts("Almeno una lettera minuscola")}`}</div>
                                        <div>{`${Ts("Almeno un numero")}`}</div>
                                        <div>{`${Ts("Almeno uno tra i seguenti caratteri:")} !@#$%^&*()_+{}\[\]:;<>,.?~\\/\-`}</div>
                                    </div>
                                </>
                            }
                                onChange={handlePasswordChange} onKeyUp={handleKeyUp} error={passwordError} />
                        </div>
                        <div className="form-row password-off">
                            <InputField id="INPUT-PASSWORD-CONFIRM" type="password" value={passwordConfirm} label={Ts("Conferma password")}
                                onChange={handlePasswordConfirmChange} onKeyUp={handleKeyUp} error={passworConfirmdError} />
                        </div>
                        <div className="button-set mt-0">
                            <Button label={Ts("Reimposta password")} onClick={handleSubmit} size={SIZES.BIG} length={LENGTHS.FULLWIDTH} status={isSubmitting && STATUS.WAITING} />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default PasswordResetPage;