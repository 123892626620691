import "./Orders.scss"

import Layout from "./Layout";
import SectionHeader from "../components/SectionHeader";
import List from "../components/List";
import { Ts, FormatDate, FormatMoney } from "../libs/js/utils";
import SvgIcon from "../components/SvgIcon";
import { ORDER_STATUS } from "../libs/js/config";

function OrdersPage() {

    const OrderListItem = (id, date, status, totalAmount, receipt) => {
        return (
            <li className={`order-list-item ${status.key}`}>
                <div>#{id}</div>
                <div>{FormatDate(date)}</div>
                <div className="status">{status.text}</div>
                <div>{FormatMoney(totalAmount)}€</div>
                <div><a href={receipt}><SvgIcon id="certificate" /></a></div>
            </li>
        )
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title={Ts("Profilo")} />
            <section>
                <div className="box-shadow">
                    <ul className="order-list">
                        <li>
                            <div>{Ts("Ordine")}</div>
                            <div>{Ts("Data")}</div>
                            <div>{Ts("Stato")}</div>
                            <div>{Ts("Totale")}</div>
                            <div></div>
                        </li>
                        {OrderListItem("6789", "2023-10-24T14:47:31.102Z", ORDER_STATUS.PENDING, "40000", "receipt.pdf")}
                        {OrderListItem("6789", "2023-10-24T14:47:31.102Z", ORDER_STATUS.ERROR, "40000", "receipt.pdf")}
                        {OrderListItem("6789", "2023-10-24T14:47:31.102Z", ORDER_STATUS.SUCCESS, "40000", "receipt.pdf")}
                    </ul>
                </div>
            </section>
        </Layout>
    )
}

export default OrdersPage;