import Layout from "./Layout";
import SectionHeader from "../components/SectionHeader";
import { useParams, useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import { useEffect, useState } from "react";
import Button, { SIZES, STATUS, TYPES } from "../components/Button";
import { Cronos } from "../libs/js/api";
import { Ts } from "../libs/js/utils";
import LoaderSmall from "../components/LoaderSmall";
import { CRONO_TYPES } from "../libs/js/config";

const CreateCronoPage = () => {
    const { cid, id } = useParams();
    const navigate = useNavigate();

    const [types, setTypes] = useState([]);
    const [type, setType] = useState("");
    const [typeError, setTypeError] = useState("");
    const [customType, setCustomType] = useState("");
    const [customTypeError, setCustomTypeError] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    useEffect(() => {
        setIsLoading(true);
        Cronos.GetTypes(0, 100).then(res => {
            if (res.data.elements) {
                setTypes(res.data.elements)
                setType(res.data.elements[0].id)
            }

            if (cid) {
                Cronos.Get(cid)
                    .then(res => {
                        console.log(res);
                        setType(res.data.crono.crono_type_id)
                        if (res.data.crono.crono_type_id == -1)
                            setCustomType(res.data.crono.title)
                        setDescription(res.data.crono.description);
                        setIsLoading(false);
                    })
            } else {
                setIsLoading(false);
            }
        })
    }, [])

    const handleSubmit = () => {
        if (isSubmitting)
            return;

        if (type === "")
            return setTypeError(Ts("Seleziona tipo di Crono"))

        if (type == -1)
            if (customType.trim() === "")
                return setCustomTypeError(Ts("Inserisci nome del controllo"))

        if (description === "")
            return setDescriptionError(Ts("Aggiungi una descrizione"))

        setIsSubmitting(true);

        let title = type == -1 ? customType : types.find(t => t.id == type).title;

        const api = cid ? Cronos.Update : Cronos.Create;

        api(title, description, id, cid, type).then(res => {
            console.log(res.data);
            navigate(`/cronospace/${id}`);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title={cid ? Ts("Modifica Crono") : Ts("Aggiungi Crono")} />
            <section>
                {isLoading && <LoaderSmall />}
                <div className="box-shadow brd-rounded">
                    <div className="form-row">
                        <div className="cc-input-field select-field">
                            <label htmlFor="TYPE">{Ts("Controllo")} *</label>
                            <select value={type} id="TYPE" data-validate="required" required="" onChange={(e) => { setType(e.currentTarget.value) }} data-filled="true">
                                {types.map(t => <option key={t.id} value={t.id}>{CRONO_TYPES.find(ct => ct.value == t.id).text}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="form-row mt-2" style={{ display: type != -1 ? 'none' : undefined }}>
                        <InputField id="CUSTOM_TYPE" type="text" value={customType} label={Ts("Nome Controllo")} placeholder={Ts("Inserisci nome del controllo")} onChange={e => { handleInputChange(e.target.value, setCustomType, setCustomTypeError) }} error={customTypeError} isRequired={true} />
                    </div>
                    <div className="form-row">
                        <InputField id="DESCRIPTION" type="textarea" value={description} label={Ts("Descrizione")} placeholder={Ts("Aggiungi descrizione, note...")} onChange={e => { handleInputChange(e.target.value, setDescription, setDescriptionError) }} error={descriptionError} isRequired={true} />
                    </div>
                    <div className="form-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '25px' }}>
                        <Button onClick={() => { navigate(-1) }} label={Ts("Annulla")} type={TYPES.OUTLINE} size={SIZES.BIG} />
                        <Button onClick={() => { handleSubmit() }} label={Ts("Salva")} size={SIZES.BIG} status={isSubmitting ? STATUS.WAITING : undefined} />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default CreateCronoPage;