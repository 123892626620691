import '../styles/post-page.scss'
import { FormatDate, Ts } from '../libs/js/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoaderSmall from '../components/LoaderSmall';
import SectionHeader from '../components/SectionHeader';
import Layout from './Layout';
import { Posts } from '../libs/js/api';
import { useBrandContext } from '../components/BrandContext';

const PostPage = () => {
  const params = useParams();
  const postId = params.id;
  const [post, setPost] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { brand } = useBrandContext();
  useEffect(() => {
    setIsLoading(true);
    // fetch post data
    Posts.GetPost(postId)
      .then(res => {
        setPost(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [])

  return (
    <Layout showHeader={false} CTA={{ label: Ts("Per info chiama {0}").replace("{0}", brand?.name), onClick: () => { window.open(`tel:${brand?.phone}`, "_self") } }}>
      <SectionHeader title={post.title} />
      <section>
        {isLoading && <LoaderSmall />}

        <div className="post">
          <div><img src={post.image_url} alt="" /></div>
          <div style={{ padding: '10px' }}>
            <div style={{ marginBottom: '10px' }} className='micro-text'>{Ts("Pubblicato il {0}").replace("{0}", FormatDate(post.published))}</div>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PostPage;