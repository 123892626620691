import { useState, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import LoaderSmall from "../components/LoaderSmall";
import { useNotification } from "../components/NotificationContext";
import { useUserContext } from "../components/UserContext";
import { Auth } from "../libs/js/api";
import { Ts } from "../libs/js/utils";
import Layout from "./Layout";

const EmailVerification = () => {
    // const { userEmail, setIsEmailValidated } = useUserContext();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenError, setTokenError] = useState(false);
    const [searchParams] = useSearchParams();
    const [isValid, setIsValid] = useState(false);
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const token = searchParams.get("token");
    async function ReSendEmail() {
        try {
            setIsSubmitting(true);
            // const res = await Auth.ReSendWelcomeEmail(userEmail)
            // console.log(res.data);
            setIsSubmitted(true);
            setTokenError(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (token) {
            setIsLoading(true);
            Auth.ValidateEmail(token)
                .then(res => {
                    console.log(res);
                    setIsValid(true);
                    // setIsEmailValidated(true);
                    // navigate('/');
                })
                .catch(err => {
                    console.log(err);
                    setTokenError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }, [])

    return (
        <Layout>
            <section>
                <div className="box-shadow brd-rounded">
                    {(isSubmitting || isLoading) && <LoaderSmall />}
                    {
                        tokenError ? <>
                            <div className="text-center">
                                <p className="text-center mb-3">
                                    {Ts("Il link è scaduto")}
                                </p>
                                {/* <a style={{
                                    cursor: 'pointer'
                                }} className="plain-link small" onClick={() => { ReSendEmail() }}>{Ts("Reinvia")}</a> */}
                            </div>
                        </> :
                            isSubmitted ? <>
                                <div className="goal-message">
                                    <div className="big-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                                    </div>
                                    <p className="text-center">
                                        {Ts("Ti abbiamo inviato una e-mail per la verifica dell'indirizzo da te inserito")}
                                    </p>
                                </div>
                            </> : isValid ? <>
                                <p className="text-center mb-3">
                                    {Ts("Congratulazioni! Abbiamo verificato il tuo indirizzo e-mail")}
                                </p>
                                <div className="text-center">
                                    {/* <p className="small-text">
                                        {Ts("Non hai ricevuto l'e-mail di verifica?")}
                                    </p> */}
                                    <a style={{
                                        cursor: 'pointer'
                                    }} className="plain-link small" onClick={() => { navigate('/login'); }}>{Ts("Vai al login")}</a>
                                </div>
                            </> : <></>
                    }
                </div>
            </section>
        </Layout>
    )
}
export default EmailVerification;