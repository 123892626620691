import { Ts } from "./utils";
import carPlaceholder from "../../images/vehicle-types-placeholders/placeholder-car.png";
import bikePlaceholder from "../../images/vehicle-types-placeholders/placeholder-motorbike.png";
import vanPlaceholder from "../../images/vehicle-types-placeholders/placeholder-van.png";
import camperPlaceholder from "../../images/vehicle-types-placeholders/placeholder-camper.png";
import trailerPlaceholder from "../../images/vehicle-types-placeholders/placeholder-trailer-small.png";

export const PERMISSION_TYPES = {
    NONE: 0,
    VIEW: 1,
    EDIT: 2
}

export const VEHICLE_TYPES = [
    {
        key: "AUTO",
        name: Ts("Auto"),
        id: "C",
        placeholderImage: carPlaceholder
    }, {
        key: "BIKE",
        name: Ts("Moto"),
        id: "B",
        placeholderImage: bikePlaceholder
    }, {
        key: "COMMERCIAL_VEHICLE",
        name: Ts("Veicolo commerciale"),
        id: "X",
        placeholderImage: vanPlaceholder
    }, {
        key: "CARAVAN",
        name: Ts("Camper"),
        id: "N",
        placeholderImage: camperPlaceholder
    }, {
        key: "TRAILER",
        name: Ts("Rimorchio"),
        id: "L",
        placeholderImage: trailerPlaceholder
    }
]

// export const CRONO_TYPES = [{
//     key: "CUSTOM",
//     value: -1,
//     text: Ts("Custom"),
//     iconId: "settings-cog"
// }, {
//     key: "OIL_CHANGE",
//     value: 1,
//     text: Ts("Cambio olio"),
//     iconId: "oil"
// }, {
//     key: "TIRE_CHANGE",
//     value: 2,
//     text: Ts("Cambio gomme"),
//     iconId: "tire"
// }, {
//     key: "INSPECTION",
//     value: 3,
//     text: Ts("Revisione"),
//     iconId: "certificate"
// }, {
//     key: "TAX",
//     value: 4,
//     text: Ts("Bollo"),
//     iconId: "certificate"
// }, {
//     key: "INSURANCE",
//     value: 5,
//     text: Ts("Assicurazione"),
//     iconId: "certificate"
// }, {
//     key: "TESTING",
//     value: 6,
//     text: Ts("Collaudo"),
//     iconId: "certificate"
// }, {
//     key: "BRAKE_OVERHAUL",
//     value: 7,
//     text: Ts("Revisione freni"),
//     iconId: "settings-cog"
// }, {
//     key: "TIMING_BELT_CHANGE",
//     value: 8,
//     text: Ts("Cambio cinghia distribuzione"),
//     iconId: "dristribution-chain"
// }, {
//     key: "SERVICE_BELT_CHANGE",
//     value: 9,
//     text: Ts("Cambio cinghia servizi"),
//     iconId: "tools"
// }]

export const CRONO_TYPES = [
    { value: 10, text: Ts('Aria condizionata'), iconId: "air-conditioner" },
    { value: 22, text: Ts('Autolavaggio'), iconId: "car-wash" },
    { value: 35, text: Ts('Batteria'), iconId: "car-battery" },
    { value: 49, text: Ts('Cinghie'), iconId: "dristribution-chain" },
    { value: 1, text: Ts('Cambio olio'), iconId: "oil" },
    { value: 2, text: Ts('Cambio gomme'), iconId: "tire" },
    { value: 64, text: Ts('Convergenza'), iconId: "convergenze" },
    { value: 80, text: Ts('Filtro del carburante'), iconId: "tools" },
    { value: 97, text: Ts('Filtro dell\'aria'), iconId: "filter-air" },
    { value: 134, text: Ts('Impianto delle sospensioni'), iconId: "suspension" },
    { value: 154, text: Ts('Inversione pneumatici'), iconId: "inv-pneumatic" },
    { value: 175, text: Ts('Liquido dei freni'), iconId: "brakes-liquid" },
    { value: 197, text: Ts('Luci'), iconId: "lights" },
    { value: 220, text: Ts('Manodopera'), iconId: "workhand" },
    { value: 7, text: Ts('Pastiglie dei freni'), iconId: "brakes" },
    { value: 244, text: Ts('Pressione degli pneumatici'), iconId: "tire-preasure" },
    { value: 6, text: Ts('Collaudo'), iconId: "collaudo" },
    { value: 5, text: Ts('Assicurazione'), iconId: "insurance" },
    { value: 4, text: Ts('Bollo'), iconId: "car-tax" },
    { value: 3, text: Ts('Revisione'), iconId: "car-revision" },
    { value: 115, text: Ts('Filtro dell\'olio'), iconId: "oil-filter" },
    { value: -1, text: Ts('Altro'), iconId: "other" }
]

export const ORDER_STATUS = {
    PENDING: {
        code: 0,
        text: Ts("In attesa"),
        key: "pending"
    },
    ERROR: {
        code: 1,
        text: Ts("Errore"),
        key: "error"
    },
    SUCCESS: {
        code: 2,
        text: Ts("OK"),
        key: "success"
    }
}