import Footer from '../components/Footer';
import Header from '../components/Header';
import '../styles/layout.scss';
import NotificationContainer from '../components/NotificationContainer';
import SvgMap from "../components/SvgMap";

const Layout = ({ showHeader = true, showFooter = true, CTA, children }) => {
  return (
    <>
      <SvgMap />
      <div className='container container-app'>
        <div className="banner-sky banner-sky-left">Banner Skyscrapper</div>
        {showHeader && <Header />}
        <NotificationContainer />
        {children}
        {showFooter && <Footer CTA={CTA} />}
        <div className="banner-sky banner-sky-right">Banner Skyscrapper</div>
      </div>
    </>
  )
};

export default Layout;