export const IsLoggedIn = () => {
    if (localStorage.getItem('authToken'))
        return true;

    return false;
}

export function FormatMoney(amount) {
    return (amount / 100).toFixed(2)
}

export function FormatDate(inputDate) {
    const dateObj = new Date(inputDate);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString();

    return `${day}/${month}/${year}`;
};

export function Logout() {
    localStorage.removeItem('authToken');
}

export function UpdateTheme(themeId) {
    document.body.classList.forEach(className => {
        if (className.startsWith("theme-"))
            document.body.classList.remove(className);
    })
    const theme = GetThemeById()[themeId];
    if (theme)
        document.body.classList.add(GetThemeById()[themeId])
}

export function GetThemeById() {
    return {
        0: "theme-default",
        1: "theme-red",
        2: "theme-green",
        3: "theme-orange",
        4: "theme-purple",
        5: "theme-grey",
        6: "theme-blue",
        7: "theme-lightblue"
    }
}

export function VatNumberCheck(vatNumber) {
    // Rimuovi spazi e caratteri non numerici
    vatNumber = vatNumber.replace(/\D/g, '');

    // Verifica che la lunghezza sia corretta
    if (vatNumber.length !== 11) {
        return false;
    }

    // Estrai le prime dieci cifre
    const mainDigits = vatNumber.slice(0, 10);

    // Estrai la cifra di controllo
    const checkDigit = parseInt(vatNumber.charAt(10), 10);

    // Calcola la cifra di controllo di parità
    let sum = 0;
    for (let i = 0; i < mainDigits.length; i++) {
        let digit = parseInt(mainDigits.charAt(i), 10);
        if (i % 2 === 1) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
    }

    const verificationDigit = (10 - (sum % 10)) % 10;

    // Verifica se la cifra di controllo coincide
    return verificationDigit === checkDigit;
}

export function CheckPasswordStrength(password) {
    // Check the length of the password
    if (password.length < 8) {
        return "Weak: Password is too short.";
    }

    // Define regular expressions for character types
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-]/.test(password);

    // Check for character types
    const characterTypes = [hasUppercase, hasLowercase, hasNumber, hasSpecialChar];
    const characterTypeCount = characterTypes.filter(Boolean).length;

    // Determine password strength based on character types
    if (characterTypeCount === 1) {
        return "Weak: Use a mix of character types.";
    } else if (characterTypeCount === 2) {
        return "Moderate: Add more character types for better security.";
    } else if (characterTypeCount >= 3) {
        return "Strong: Password is strong.";
    }

    return "Weak: Password does not meet minimum criteria.";
}

const translations = {}

export function Ts(key) {
    const lang = "it"
    if (translations[key] && translations[key][lang])
        return translations[key][lang];
    return key;
}