import { useNavigate } from "react-router-dom";
import { VEHICLE_TYPES } from "../libs/js/config";
import { Ts } from "../libs/js/utils";
import Button, { SIZES } from "./Button";
import SvgIcon from "./SvgIcon";

const CronospaceSnippet = ({ id, make, model, plate, image_url, onClick, type }) => {

    const navigate = useNavigate();

    return (
        <div onClick={onClick} className="cronospace-snippet">
            <img src={image_url || VEHICLE_TYPES.find(t => t.key == type).placeholderImage} alt="" />
            <div className="info-box">
                <div>
                    <h3 className="title-1">{make} - {model}</h3>
                    <p>{plate}</p>
                </div>
                <div className="cta-row">
                    <Button onClick={e => { e.stopPropagation(); navigate(`/cronospace/${id}/crono/create`) }} label={<><SvgIcon id="add-file" /><span>{Ts("Aggiungi Crono")}</span></>} size={SIZES.SMALL} />
                </div>
                {/* <div className="property-row">
                    <img className="avatar" src="assets/avatar-demo.jpg" alt="" />
                    <div>
                        <div className="micro-text">Propietà CronoSpace:</div>
                        <h3 className="title-2">M.Plank</h3>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default CronospaceSnippet;