import { useState } from "react";
import { Ts } from "../libs/js/utils";
import Button, { SIZES, TYPES } from "./Button";

function ImageUploader({
    iniImages = [],
    ctaLabel = Ts("Carica immagine"),
    maxImagesN = 10,
    minImagesN = 0,
    onChange = images => { console.log('overwrite', images) }
}) {

    const [images, setImages] = useState(iniImages);

    function DeleteImage(index) {
        const _images = images.filter((_, i) => i !== index);
        setImages(_images)
        onChange(_images);
    }

    function UploadImage() {
        const inputFile = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = async e => {
            const files = e.target.files;
            const file = files[0];
            if (file) {
                try {
                    // const res = await Upload.Put(file);
                    const _images = [...images, file];
                    setImages(_images);
                    onChange(_images);
                    // console.log(res.data.url);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        inputFile.click();
    }
    return (
        <div className="uploader">
            <div className="thumbnails">
                {images.map((image, index) => {
                    const src = image instanceof File ? URL.createObjectURL(image) : image;
                    return (
                        <div key={index} className="thumbnail">
                            {image.type.includes('video/') ? <video src={src} alt="" /> : <img src={src} alt="" />}
                            <span onClick={() => DeleteImage(index)}>X</span>
                        </div>
                    )
                })}
            </div>
            {images.length < maxImagesN && <Button onClick={() => { UploadImage() }} label={ctaLabel} type={TYPES.OUTLINE} size={SIZES.SMALL} />}
        </div>
    )
}
export default ImageUploader;