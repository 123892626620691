import "./CronoSpace.scss";
import { useEffect, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import Layout from "./Layout";
import Button, { SIZES } from "../components/Button";
import { Cronos, Cronospaces } from "../libs/js/api";
import { useNavigate, useParams } from "react-router-dom";
import LoaderSmall from "../components/LoaderSmall";
import List from "../components/List";
import CronoListItem from "../components/CronoListItem";
import { Ts } from "../libs/js/utils";
import SvgIcon from "../components/SvgIcon";
import Modal from "../components/Modal";
import { PERMISSION_TYPES, VEHICLE_TYPES } from "../libs/js/config";

const CronoSpacePage = () => {
    const { id } = useParams();
    const tabs = {
        CRONOS: "CRONOS",
        VEHICLE: "VEHICLE"
    }
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tab, setTab] = useState(tabs.CRONOS);
    const [cronoSpace, setCronoSpace] = useState({
        cronospace: {},
        user_data: {}
    })
    const [isLoading, setIsLoading] = useState(false);

    // const [cronos, setCronos] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true)
        Cronospaces.Get(id).then(res => {
            setCronoSpace(res.data)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    const title = `${cronoSpace.cronospace.manufacturer || ""} ${cronoSpace.cronospace.model || ""}`;

    const label = (
        <>
            <SvgIcon id="add-file" />
            <span>{Ts("Aggiungi Crono")}</span>
        </>
    )

    const deleteCronoSpace = async () => {
        try {
            setIsLoading(true)
            await Cronospaces.Delete(id)
            navigate('/garage');
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const canEdit = cronoSpace?.cronospace?.permission_type == PERMISSION_TYPES.EDIT;

    const options = [...canEdit ? [{
        entry: (<><SvgIcon id="edit" /><span>{Ts("Modifica")}</span></>),
        onClick: e => {
            navigate(`edit`)
        }
    }] : [], ...canEdit ? [{
        entry: (<><SvgIcon id="delete" /><span>{Ts("Elimina")}</span></>),
        onClick: async e => {
            setShowDeleteModal(true);
        }
    }] : [], {
        entry: (<><SvgIcon id="share" /><span>{Ts("Condividi")}</span></>),
        hasSeparator: canEdit,
        onClick: e => {
            navigator.share({
                title: title,
                text: `${title} - ${cronoSpace?.cronospace?.plate}`,
                url: window.location.href
            })
        }
    }]

    return (
        <Layout showHeader={false}>
            <SectionHeader title={title} options={options} />
            <section>
                {isLoading && <LoaderSmall />}
                <div className="cronospace-card">
                    <div className="car-photo">
                        {canEdit ? <Button label={label} onClick={() => { navigate('crono/create') }} size={SIZES.SMALL} /> : null}
                        <img src={cronoSpace.cronospace.image_url || VEHICLE_TYPES.find(t => t.key == cronoSpace?.cronospace?.type)?.placeholderImage} />
                        {/* <div className="pagination-row">
                            <div className="bullet active"></div>
                            <div className="bullet"></div>
                            <div className="bullet"></div>
                        </div> */}
                    </div>

                    <ul className="tabs">
                        <li onClick={() => { setTab(tabs.CRONOS) }} className={tab === tabs.CRONOS ? "active" : undefined}>
                            <a>{Ts("Lista Crono")}</a>
                        </li>
                        <li onClick={() => { setTab(tabs.VEHICLE) }} className={tab === tabs.VEHICLE ? "active" : undefined}>
                            <a>{Ts("Dati veicolo")}</a>
                        </li>
                    </ul>


                    <ul className="cronospace-data" style={{ display: tab !== tabs.VEHICLE ? "none" : undefined }}>

                        <li className="pb-2 mb-1 border-sep">
                            <div>
                                <span>{Ts("Proprietario")}</span>
                                <h4 className="title-2">{cronoSpace.user_data.display_name || "---"}</h4>
                            </div>

                        </li>
                        <li>
                            <div>
                                <span>{Ts("Marca")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.manufacturer || "---"}</h4>
                            </div>

                        </li>
                        <li>
                            <div>
                                <span>{Ts("Modello")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.model || "---"}</h4>
                            </div>

                        </li>
                        <li>
                            <div>
                                <span>{Ts("Targa")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.plate || "---"}</h4>
                            </div>

                        </li>
                        <li>
                            <div>
                                <span>{Ts("Numero seriale")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.serial_number || "---"}</h4>
                            </div>

                        </li>
                        <li>
                            <div>
                                <span>{Ts("Tipo di cambio")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.transmission || "---"}</h4>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>{Ts("Numero di porte")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.doors_number || "---"}</h4>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>{Ts("Colore")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.color || "---"}</h4>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>{Ts("Carburante")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.fuel || "---"}</h4>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>{Ts("Anno immatricolazione")}</span>
                                <h4 className="title-2">{cronoSpace.cronospace.registration_year || "---"}</h4>
                            </div>
                        </li>
                    </ul>


                    <ul className="cronospace-history" style={{ display: tab !== tabs.CRONOS ? "none" : undefined }}>
                        <List emptyPlaceholder={<div className="empty">{Ts("Non ci sono crono per questo veicolo")}</div>} api={(page, pageSize) => { return Cronos.GetList(id, page, pageSize) }} format={(item, onDelete) => <CronoListItem canEdit={canEdit} onDelete={onDelete} key={item.crono.id} crono={item} />} />
                        {/* <li className="uncollapsed">
                                <div className="m-row">
                                    <div className="icon-menu">
                                        <SvgIcon id="oil" />
                                    </div>
                                    <div className="copy-menu">
                                        <strong>Sostituzione Olio</strong>
                                        <p className="small-text">13/05/2023</p>
                                    </div>
                                    <svg className="svg-icon arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </div>
                                <div className="extra-row">
                                    <p>
                                        Cambio olio 75W, prossimo cambio olio 90.000km o settembre 2024.
                                        <br />
                                        Cambio filtro olio per uso.
                                    </p>
                                    <p>Documento allegato</p>
                                    <a href="#">
                                        FAttura 20012k.pdf
                                        <SvgIcon id="download" />
                                    </a>
                                    <div className="button-set">
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="delete" />
                                            <span>Elimina</span>
                                        </button>
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="edit" />
                                            <span>Modifica</span>
                                        </button>
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="send" />
                                            <span>Invia Crono</span>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="m-row">
                                    <div className="icon-menu">
                                        <SvgIcon id="winter-tire" />
                                    </div>
                                    <div className="copy-menu">
                                        <strong>Cambio gomme invernali</strong>
                                        <p className="small-text">13/05/2023</p>
                                    </div>
                                    <svg className="svg-icon arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </div>
                                <div className="extra-row">
                                    <p>
                                        Cambio olio 75W, prossimo cambio olio 90.000km o settembre 2024.
                                        <br />
                                        Cambio filtro olio per uso.
                                    </p>
                                    <p>Documento allegato</p>
                                    <a href="#">
                                        FAttura 20012k.pdf
                                        <SvgIcon id="download" />
                                    </a>
                                    <div className="button-set">
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="delete" />
                                            <span>Elimina</span>
                                        </button>
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="edit" />
                                            <span>Modifica</span>
                                        </button>
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="send" />
                                            <span>Invia Crono</span>
                                        </button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="m-row">
                                    <div className="icon-menu">
                                        <SvgIcon id="settings-cog" />
                                    </div>
                                    <div className="copy-menu">
                                        <strong>Manutenzione annuale</strong>
                                        <p className="small-text">13/05/2023</p>
                                    </div>
                                    <SvgIcon id="chevron-down" />
                                </div>
                                <div className="extra-row">
                                    <p>
                                        Cambio olio 75W, prossimo cambio olio 90.000km o settembre 2024.
                                        <br />
                                        Cambio filtro olio per uso.
                                    </p>
                                    <p>Documento allegato</p>
                                    <a href="#">
                                        FAttura 20012k.pdf
                                        <SvgIcon id="download" />
                                    </a>
                                    <div className="button-set">
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="delete" />
                                            <span>Elimina</span>
                                        </button>
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="edit" />
                                            <span>Modifica</span>
                                        </button>
                                        <button className="btn-button small no-border">
                                            <SvgIcon id="send" />
                                            <span>Invia Crono</span>
                                        </button>
                                    </div>
                                </div>
                            </li> */}
                    </ul>

                </div>
                <Modal onClose={() => { setShowDeleteModal(false) }} onConfirm={() => { deleteCronoSpace() }} isOpen={showDeleteModal} title={Ts("Sei sicuro di voler eliminare questo CronoSpace?")} />
            </section>
        </Layout>
    )
}

export default CronoSpacePage;