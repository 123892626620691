import "./CustomerListItem.scss";
import { useState, useEffect } from "react";
import { Cronospaces } from "../libs/js/api";
import { FormatDate, Ts } from "../libs/js/utils";
import { NOTIFICATION_TYPES } from "./Notification";
import { useNotification } from "./NotificationContext";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "./SvgIcon";
import { CRONO_TYPES } from "../libs/js/config";
import Modal from "./Modal";
import LoaderSmall from "./LoaderSmall";
import ContextMenu from "./ContextMenu";
import Button, { SIZES, TYPES } from "./Button";
import avatarPlaceholder from "../images/placeholder-user.svg";

const CustomerListItem = ({ customer, onDelete }) => {
    const { name, surname, image_url } = customer.user_data;
    const [cronospaces, setCronospaces] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCronospaces, setIsLoadingCronospaces] = useState(false);
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const Delete = async () => {
        setIsLoading(true);
        try {
            // TODO: delete customer
            onDelete();
        } catch (error) {
            console.error(error)
            addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isCollapsed == false && !cronospaces) {
            setIsLoadingCronospaces(true);
            Cronospaces.GetList(0, 3, customer.user.id)
                .then(res => {
                    setCronospaces(res.data.elements)
                    setIsLoadingCronospaces(false);
                })
        }
    }, [isCollapsed])

    // const options = [{
    //     entry: (<><SvgIcon id="edit" /><span>{Ts("Modifica")}</span></>),
    //     onClick: e => { }
    // }, {
    //     entry: (<><SvgIcon id="delete" /><span>{Ts("Elimina")}</span></>),
    //     onClick: async e => { }
    // }, {
    //     entry: (<><SvgIcon id="share" /><span>{Ts("Condividi")}</span></>),
    //     hasSeparator: true,
    //     onClick: e => { }
    // }]

    let className = "list-item";
    if (isCollapsed == false)
        className += " uncollapsed";

    return (
        <>
            <li className={className} style={{ position: 'relative' }}>
                {isLoading && <LoaderSmall />}
                <div className="outer" onClick={() => { setIsCollapsed(!isCollapsed) }}>
                    <div className="preview">
                        <img className="avatar" src={image_url || avatarPlaceholder} />
                    </div>
                    <div className="info">
                        <p>{`${name} ${surname}`}</p>
                    </div>
                    <div className="actions">
                        <svg className="svg-icon arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                        <ContextMenu options={[]} />
                    </div>
                </div>
                <div className="inner">
                    {/* <p>{`${name} ${surname}`}</p> */}
                    {isLoadingCronospaces && <div>{Ts("Caricamento veicoli...")}</div>}
                    {cronospaces && cronospaces.length == 0 && <div>{Ts("Senza veicoli:")}</div>}
                    {cronospaces && cronospaces.length > 0 &&
                        <div>
                            <div>{Ts("Lista veicoli:")}</div>
                            {cronospaces.map(cronospace => {
                                return <div><SvgIcon id="car" />{cronospace.plate}</div>
                            })}
                        </div>
                    }
                    <div>
                        <Button type={TYPES.OUTLINE} size={SIZES.SMALL} label={<><SvgIcon id="add-car" /> {Ts("AGGIUNGI CRONOSPACE")}</>} onClick={() => {
                            navigate('/cronospace/create', {
                                state: {
                                    customerId: customer.user.id
                                }
                            })
                        }} />
                    </div>
                </div>
            </li>
            <Modal onClose={() => { setShowModal(false) }} isOpen={showModal} onConfirm={() => { setShowModal(false); Delete() }} message={Ts("Sei sicuro di voler eliminare questo Crono?")} />
        </>
    )
}
export default CustomerListItem;