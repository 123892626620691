import "./index.scss";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import PostPage from "./pages/Post";
import { UserContextProvider, useUserContext } from "./components/UserContext";
import { useEffect, useState } from "react";
import { Main } from "./libs/js/api";
import ProfilePage from "./pages/Profile";
import GaragePage from "./pages/Garage";
import CreateCronoSpacePage from "./pages/CreateCronoSpace";
import AccountPage from "./pages/Account";
import { BrandContextProvider, useBrandContext } from "./components/BrandContext";
import CronoSpacePage from "./pages/CronoSpace";
import CreateCronoPage from "./pages/CreateCrono";
import PasswordRecoveryPage from "./pages/PasswordRecovery";
import WorkshopPage from "./pages/Workshop";
import CustomerManagementPage from "./pages/CustomerManagement";
import AddCustomerPage from "./pages/AddCustomer";
import { NotificationProvider } from "./components/NotificationContext";
import CronoStoryPage from "./pages/CronoStory";
import PasswordResetPage from "./pages/PasswordReset";
import { UpdateTheme } from "./libs/js/utils";
import EmailVerification from "./pages/EmailVerification";
import Loader from "./components/Loader";
import UICommonsPage from "./pages/UICommons";
import BillingAddressPage from "./pages/BillingAddress";
import CreatePostPage from "./pages/CreatePost";
import OrdersPage from "./pages/Orders";
import NewsPage from "./pages/News";
import LegalPage from "./pages/Legal";
import MobileAppCallbacks from "./libs/js/mobile-app-callbacks";

export default function App() {
  const { brand, setBrand } = useBrandContext();
  const { isLoggedIn, setIsLoggedIn, setUser, setUserEmail, setWorkshop, isEmailValidated, setIsEmailValidated } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Main.Init()
      .then(res => {
        const { auth_token } = res.data;
        const savedAuthToken = localStorage.getItem("authToken");
        if ((auth_token == "" || auth_token == null) && savedAuthToken) {
          localStorage.removeItem("authToken");
          window.location.reload();
        } else if (auth_token) {
          localStorage.setItem("authToken", auth_token);
        }
        // console.log(res);
        setBrand(res.data.brand)
        setUser(res.data.user_data)
        setUserEmail(res.data.user?.email)
        setIsEmailValidated(res.data.user?.email_validated)
        setWorkshop(res.data.garage_data)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [])

  useEffect(() => {
    UpdateTheme(brand?.theme_id);
  }, [brand])

  // useEffect(() => {
  //   if (brand === null && isLoading === false) {
  //     setIsLoading(true);
  //     GetBrand().then(res => {
  //       setBrand(res.data);
  //       UpdateTheme(res.data.theme_id);
  //       setIsLoading(false);
  //     })
  //   }

  //   if (!user && isLoggedIn) {
  //     GetUserData().then(res => {
  //       setUser(res.data.user_data);
  //     });
  //   }
  // }, [user, setUser, isLoggedIn, brand, isLoading])

  if (isLoading)
    return (
      <Loader />
    )

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={isEmailValidated == false ? <Navigate to="/email-verification" /> : <HomePage />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <LoginPage onLogin={() => { setIsLoggedIn(true) }} />} />
        <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <RegisterPage onRegister={() => { setIsLoggedIn(true) }} />} />
        <Route path="/password-recovery" element={isLoggedIn ? <Navigate to="/" /> : <PasswordRecoveryPage />} />
        <Route path="/reset-password" element={isLoggedIn ? <Navigate to="/" /> : <PasswordResetPage />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/post/:id" element={<PostPage />} />
        <Route path="/profile" element={isLoggedIn ? <ProfilePage /> : <Navigate to="/" />} />
        <Route path="/garage" element={isLoggedIn ? <GaragePage /> : <Navigate to="/" />} />
        <Route path="/cronospace/:id/edit" element={isLoggedIn ? <CreateCronoSpacePage /> : <Navigate to="/" />} />
        <Route path="/cronospace/create" element={isLoggedIn ? <CreateCronoSpacePage /> : <Navigate to="/" />} />
        <Route path="/account" element={isLoggedIn ? <AccountPage /> : <Navigate to="/" />} />
        <Route path="/cronospace/:id" element={<CronoSpacePage />} />
        <Route path="/cronospace/:id/crono/create" element={isLoggedIn ? <CreateCronoPage /> : <Navigate to="/" />} />
        <Route path="/cronospace/:id/crono/:cid/edit" element={isLoggedIn ? <CreateCronoPage /> : <Navigate to="/" />} />
        <Route path="/cronospace/:id/crono/:cid" element={isLoggedIn ? <CronoStoryPage /> : <Navigate to="/" />} />
        <Route path="/workshop" element={isLoggedIn ? <WorkshopPage /> : <Navigate to="/" />} />
        <Route path="/workshop/customers" element={isLoggedIn ? <CustomerManagementPage /> : <Navigate to="/" />} />
        <Route path="/workshop/customers/new" element={isLoggedIn ? <AddCustomerPage /> : <Navigate to="/" />} />
        <Route path="/billing-address" element={isLoggedIn ? <BillingAddressPage /> : <Navigate to="/" />} />
        <Route path="/post/create" element={isLoggedIn ? <CreatePostPage /> : <Navigate to="/" />} />
        <Route path="/orders" element={isLoggedIn ? <OrdersPage /> : <Navigate to="/" />} />
        <Route path="/ui-commons" element={<UICommonsPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/legal/:doc" element={<LegalPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <NotificationProvider>
    <BrandContextProvider>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </BrandContextProvider>
  </NotificationProvider>
);