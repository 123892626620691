import React, { useEffect, useRef } from "react";
import { Ts } from "../libs/js/utils";
import { useNotification } from "./NotificationContext";

export const NOTIFICATION_TYPES = {
    SUCCESS: "alert-success",
    ERROR: "alert-danger",
    WARNING: "alert-warning"
}

const Notification = ({ notification }) => {
    const { removeNotification } = useNotification();
    const notificationRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            notificationRef.current.classList.add('show');
        }, 150)
        const timer = setTimeout(() => {
            setTimeout(() => {
                removeNotification(notification.id);
            }, 150)
            notificationRef.current.classList.remove('show');
        }, 5000); // Automatically remove notification after 5 seconds

        return () => {
            clearTimeout(timer);
        };
    }, [notification.id, removeNotification]);

    return (
        <div ref={notificationRef} className={`alert ${notification.type} alert-dismissible fade`} role="alert">
            {notification.type != NOTIFICATION_TYPES.SUCCESS && < strong > {Ts("Attenzione!")}</strong>} {notification.message}
            {/* <button type="button" className="close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button> */}
        </div >
    );
};

export default Notification;