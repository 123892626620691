import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBrandContext } from './BrandContext';
import imageHeader from '../images/image-header.svg';
import { useUserContext } from './UserContext';

const Header = () => {
    const { brand } = useBrandContext();
    const { workshop } = useUserContext();
    const [scrollY, setScrollY] = useState(0);
    const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
    const navigate = useNavigate();

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Update header state based on scroll position
    useEffect(() => {
        setIsHeaderCollapsed(scrollY > 190);
    }, [scrollY]);

    let className = "header-logo-full";
    if (isHeaderCollapsed)
        className = "header-logo-min";

    return (
        <header className={className}>
            <img onClick={() => { navigate('/') }} src={brand?.logo_min_url} className="logo-min" alt="" />
            {workshop == null && <div className="image-header">
                <img src={imageHeader} alt="" />
            </div>}
        </header>
    )
};

export default Header;