import './Modal.scss';
import React from 'react';
import { Ts } from '../libs/js/utils';
import SvgIcon from "./SvgIcon";

const Modal = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;

    return (
        <div className="dialog dialog-small open">
            <div className="dialog-wrapper">
                <div onClick={() => { onClose() }} className="close">
                    <SvgIcon id="close-x" />
                </div>
                <h2 className="title-1">{title}</h2>
                <p>{message}</p>
                <div className="button-set">
                    {/* <button className="btn-button small no-border">
                        <span>Cancella</span>
                    </button> */}
                    <button onClick={() => { onClose() }} className="btn-button small outline">
                        <span>{Ts("Anulla")}</span>
                    </button>
                    <button onClick={() => { onConfirm() }} className="btn-button small">
                        <span>{Ts("Accetta")}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;