import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button, { SIZES, STATUS } from "../components/Button";
import InputField from "../components/InputField";
import { NOTIFICATION_TYPES } from "../components/Notification";
import { useNotification } from "../components/NotificationContext";
import SectionHeader from "../components/SectionHeader";
import { useUserContext } from "../components/UserContext";
import { SetUserData, User } from "../libs/js/api";
import { Ts } from "../libs/js/utils";
import Layout from "./Layout";
import CountriesByISOCode from "../libs/js/countries";
const BillingAddressPage = () => {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [surname, setSurname] = useState("");
    const [surnameError, setSurnameError] = useState("");
    const [country, setCountry] = useState("");
    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [postalCodeError, setPostalCodeError] = useState("");
    const [province, setProvince] = useState("");
    const [provinceError, setProvinceError] = useState("");
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const onCountryChange = (e) => {
        setCountry(e.currentTarget.value)
    }

    const { addNotification } = useNotification();

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        (async () => {
            const res = await User.GetBillingAddress()
            const data = res.data;
            setName(data.firstName);
            setSurname(data.lastName);
            setCountry(data.country);
            setAddress(data.address);
            setPostalCode(data.postal_code);
            setProvince(data.province);
            setPhone(data.phone);
        })()
    }, []);

    const navigate = useNavigate();

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    const handleSubmit = async () => {
        if (name.trim() === "") {
            setNameError(Ts("Inserisci il tuo nome"))
            return;
        }
        if (surname.trim() === "") {
            setSurnameError(Ts("Inserisci il tuo cognome"))
            return;
        }
        if (address.trim() === "") {
            setAddressError(Ts("Inserisci indirizzo"))
            return;
        }
        if (postalCode.trim() === "") {
            setPostalCodeError(Ts("Inserisci cod. postale"))
            return;
        }
        if (province.trim() === "") {
            setProvinceError(Ts("Inserisci provincia"))
            return;
        }
        if (city.trim() === "") {
            setCityError(Ts("Inserisci città"))
            return;
        }
        if (phone.trim() === "") {
            setPhoneError(Ts("Inserisci numero di telefono"))
            return;
        }
        let phoneFormatted = phone.replace(/\s/g, "");
        const phonePattern = /\+?\d{5,15}/g;
        const isPhoneValid = phonePattern.test(phoneFormatted);
        if (!isPhoneValid) {
            setPhoneError(Ts("Numero di telefono non valido"))
            return;
        }

        try {
            setIsSubmitting(true)
            await User.SaveBillingAddress(name, surname, country, address, postalCode, province, city, phone)
            addNotification(Ts("Modifiche salvate"), NOTIFICATION_TYPES.SUCCESS)
        } catch (error) {
            addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title={Ts("Profilo - Gestione indirizzi")} />
            <section>
                <div className="box-shadow">
                    <p>{Ts("Indirizzo di fatturazione")}</p>
                    <div className="form-row">
                        <InputField label={Ts("Nome")} value={name} isRequired={true} error={nameError} onChange={e => handleInputChange(e.target.value, setName, setNameError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Cognome")} value={surname} isRequired={true} error={surnameError} onChange={e => handleInputChange(e.target.value, setSurname, setSurnameError)} />
                    </div>
                    <div className="form-row">
                        <div className="cc-input-field select-field">
                            <label htmlFor="COUNTRY">{Ts("Paese")} *</label>
                            <select value={country} id="COUNTRY" data-validate="required" required="" onChange={onCountryChange} data-filled="true">
                                {Object.entries(CountriesByISOCode).map(([key, value]) => {
                                    return (<option value={key}>{value.N}</option>)
                                })}
                            </select>
                            <div className="error"></div>
                        </div>
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Via e numero")} value={address} isRequired={true} error={addressError} onChange={e => handleInputChange(e.target.value, setAddress, setAddressError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("CAP")} value={postalCode} isRequired={true} error={postalCodeError} onChange={e => handleInputChange(e.target.value, setPostalCode, setPostalCodeError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Provincia")} value={province} isRequired={true} error={provinceError} onChange={e => handleInputChange(e.target.value, setProvince, setProvinceError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Città")} value={city} isRequired={true} error={cityError} onChange={e => handleInputChange(e.target.value, setCity, setCityError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Telefono")} value={phone} isRequired={true} error={phoneError} onChange={e => handleInputChange(e.target.value, setPhone, setPhoneError)} />
                    </div>
                </div>
            </section>
            <div className="button-set fixed-bottom">
                <Button label={Ts("Salva Modifiche")} onClick={() => { handleSubmit() }} size={SIZES.BIG} status={isSubmitting && STATUS.WAITING} />
            </div>
        </Layout>
    )

}
export default BillingAddressPage;