import React, { useState, useEffect, useRef } from 'react';
import { Ts } from '../libs/js/utils';
import Button, { SIZES, TYPES } from './Button';

function List({ api = async () => { return { data: { elements: [] } } }, format = item => { console.log(item) }, errorPlaceholder, emptyPlaceholder, onLoad }) {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [isLoading, setIsLoading] = useState(false);
    const [allItemsLoaded, setAllItemsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const sentinelRef = useRef(null);

    const Retry = () => {
        setIsError(false);
        loadMoreData();
    }

    // probably the best way to re-init the component is to change the key prop <List key={valueToChange} />
    // function ReInit() {
    //     setData([])
    //     setPage(0)
    //     setPageSize(15)
    //     setIsLoading(false)
    //     setAllItemsLoaded(false)
    //     setIsError(false)
    // }

    const renderErrorPlaceholder = (Retry) => {
        if (errorPlaceholder)
            return errorPlaceholder;

        return <div style={{ padding: '10px', textAlign: 'center' }}>
            <p>{Ts("Si è verificato un errore")}</p>
            <Button label="Riprova" onClick={() => { Retry() }} size={SIZES.SMALL} type={TYPES.OUTLINE} />
        </div>
    }

    const renderEmptyPlaceholder = () => {
        if (emptyPlaceholder)
            return emptyPlaceholder;

        return <div style={{ padding: '10px', textAlign: 'center' }}>{Ts("Non ci sono elementi")}</div>

    }

    const loadMoreData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                const res = await api(page, pageSize)
                if (res.data.elements) {
                    const items = [...data, ...res.data.elements];
                    setData(items)
                    setPage(page + 1)
                    if (res.data.elements.length < pageSize) {
                        // all items loaded
                        setAllItemsLoaded(true);

                    }
                    if (typeof onLoad === 'function')
                        onLoad(items)
                }
            } catch (error) {
                console.error(error);
                setIsError(true);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const sentinel = entries[0];
            if (sentinel.isIntersecting) {
                loadMoreData();
            }
        });

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => {
            if (sentinelRef.current) {
                observer.unobserve(sentinelRef.current);
            }
        };
    }, []);

    const onDelete = (index) => {
        // Create a copy of the data array
        const newData = [...data];
        // Set the item at the specified index to null
        newData[index] = null;
        setData(newData);
    };

    return (
        <div>
            {/* Render your data */}
            {data.map((item, index) => {
                if (item)
                    return format(item, () => { onDelete(index) }, index)
            })}
            {isLoading == false && isError &&
                renderErrorPlaceholder(Retry)
            }
            {isLoading == false && isError == false && data.filter(item => item != null).length < 1 &&
                renderEmptyPlaceholder()
            }
            {isLoading &&
                <div style={{ padding: '10px', textAlign: 'center' }}>{Ts("Caricamento in corso...")}</div>
            }
            {/* Use a sentinel element as a trigger for loading more data */}
            {allItemsLoaded === false && <div ref={sentinelRef} style={{ height: '1px' }} />}
        </div>
    );
}

export default List;