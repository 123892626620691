import '../styles/home-page.scss';

import { Ts } from '../libs/js/utils';
import { Link, useNavigate } from 'react-router-dom';
import Button, { SIZES } from '../components/Button';
import { useUserContext } from '../components/UserContext';
import PostPreview from '../components/PostPreview';
import { useEffect, useState } from 'react';
import { Posts } from '../libs/js/api';
import LoaderSmall from '../components/LoaderSmall';
import Layout from './Layout';
import List from '../components/List';
import { useBrandContext } from '../components/BrandContext';
import SvgIcon from "../components/SvgIcon";
const HomePage = () => {
  const { user, isLoggedIn } = useUserContext();
  const { brand } = useBrandContext();
  // const [postsList, setPostsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // setIsLoading(true);
    // Posts.GetList().then(res => setPostsList(res.data.elements)).finally(() => setIsLoading(false))
  }, [])

  return (

    <Layout>
      <section className='section-home'>
        {/* <!-- SEARCHBOX BAR --> */}
        {/* 
        <div className="search-box box-shadow brd-rounded">
          <input type="search" placeholder={Ts("Cerca...")} />
          <SvgIcon id="search" />
        </div>
         */}
        {/* <!-- SEARCHBOX BAR --> */}


        <div className="buttons-home">
          <div className='text-center mb-4 search-btn'>
            <Button label={<>
              {Ts("RICERCA VEICOLI")}<SvgIcon id="search" />
            </>} disabled={true} />
          </div>
          <div className='text-center mb-4'>
            <Button label={Ts("NOTIZIE DA CRONOCAR")} onClick={() => { navigate("/news") }} size={SIZES.BIG} disabled={false} />
          </div>
          {isLoggedIn == false && <>
            <div className='text-center mb-4'>
              <Link to="/login" className="plain-link">{Ts("ACCEDI")}</Link>
            </div>
            <div className='text-center'>
              <Link to="/register" className="plain-link">{Ts("REGISTRATI")}</Link>
            </div>
          </>}
          {isLoggedIn && <>
            <div className='text-center mb-4'>
              <Button label={Ts("CONTATTA LA TUA OFFICINA")} onClick={() => { navigate("/news") }} size={SIZES.BIG} disabled={true} />
            </div>
            <div className='text-center mb-4'>
              <Button label={Ts("NOTIZIE DALLA TUA OFFICINA")} onClick={() => { navigate("/news") }} size={SIZES.BIG} disabled={true} />
            </div>
            {/* <div className='text-center'>
              <Button label={Ts("SERVIZIO {N}")} onClick={() => { navigate("/news") }} size={SIZES.BIG} disabled={true} />
            </div> */}
          </>}

        </div>

      </section>
    </Layout>

  );
};

export default HomePage;