import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button, { SIZES, STATUS, TYPES } from "../components/Button";
import InputField from "../components/InputField";
import SectionHeader from "../components/SectionHeader";
import { Ts } from "../libs/js/utils";
import Layout from "./Layout";
import { Upload, Workshop } from "../libs/js/api";
import ImageUploader from "../components/ImageUploader";
import SvgIcon from "../components/SvgIcon";

const AddCustomerPage = () => {
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    // const [phoneNumberError, setPhoneNumberError] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    const handleSubmit = async () => {

        if (firstName === "")
            return setFirstNameError(Ts("Inserisci il nome"))
        if (lastName === "")
            return setLastNameError(Ts("Inserisci il cognome"))
        if (email === "")
            return setEmailError(Ts("Inserisci l'e-mail"))
        // if (phoneNumber === "")
        //     return setPhoneNumberError(Ts("Inserisci il numero di telefono"))

        try {
            setIsSubmitting(true)
            let imageUrl = "";
            for (const imageFile of imagesToUpload) {
                const res = await Upload.Put(imageFile);
                imageUrl = res.data.url;
            }

            const res = await Workshop.AddCustomer(firstName, lastName, email, imageUrl, phoneNumber);
            console.log(res.data);
            navigate('/workshop/customers');
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onImageUploaderChange = images => {
        const _imagesToUpload = images.filter(image => image instanceof File);
        setImagesToUpload(_imagesToUpload);
    }

    return (
        <>
            <Layout showHeader={false}>
                <SectionHeader title={Ts("Aggiungi Cliente")} />
                <section>
                    <div className="box-shadow">
                        <div className="form-row">
                            <ImageUploader maxImagesN={1} onChange={onImageUploaderChange} ctaLabel={<><SvgIcon id="add-photo" /> {Ts("Carica foto")}</>} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Nome")} value={firstName} isRequired={true} error={firstNameError} onChange={e => handleInputChange(e.target.value, setFirstName, setFirstNameError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Cognome")} value={lastName} isRequired={true} error={lastNameError} onChange={e => handleInputChange(e.target.value, setLastName, setLastNameError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("E-mail")} value={email} isRequired={true} error={emailError} onChange={e => handleInputChange(e.target.value, setEmail, setEmailError)} />
                        </div>
                        <div className="form-row">
                            <InputField label={Ts("Telefono")} value={phoneNumber} onChange={e => handleInputChange(e.target.value, setPhoneNumber)} />
                        </div>
                        <div className="form-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '25px' }}>
                            <Button onClick={() => { navigate(-1) }} label="Annulla" type={TYPES.OUTLINE} size={SIZES.BIG} />
                            <Button onClick={() => { handleSubmit() }} label="Salva" size={SIZES.BIG} status={isSubmitting ? STATUS.WAITING : undefined} />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default AddCustomerPage;