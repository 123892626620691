import "./CreateCronoSpace.scss";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button, { SIZES, STATUS, TYPES } from "../components/Button";
import InputField from "../components/InputField";
import SectionHeader from "../components/SectionHeader";
import { Cronospaces, Upload } from "../libs/js/api";
import Layout from "./Layout";
import { Ts } from "../libs/js/utils";
import { useNotification } from "../components/NotificationContext";
import { NOTIFICATION_TYPES } from "../components/Notification";
import LoaderSmall from "../components/LoaderSmall";
import SvgIcon from "../components/SvgIcon";
import { VEHICLE_TYPES } from "../libs/js/config";

const CreateCronospacePage = () => {
    const { id } = useParams();
    const location = useLocation();
    const customerId = location.state ? location.state.customerId : null;
    console.log(`customerId: ${customerId}`);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [customMake, setCustomMake] = useState("");
    const [customModel, setCustomModel] = useState("");
    const [plate, setPlate] = useState("");
    const [type, setType] = useState(VEHICLE_TYPES[0].id);
    const [more, setMore] = useState("");
    const [pics, setPics] = useState([]);
    const [makeError, setMakeError] = useState("");
    const [modelError, setModelError] = useState("");
    const [customMakeError, setCustomMakeError] = useState("");
    const [customModelError, setCustomModelError] = useState("");
    const [plateError, setPlateError] = useState("");
    const [typeError, setTypeError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        Cronospaces.GetModels().then(res => {
            const { makes } = res.data;
            setMakes(makes.filter(make => make.models.length && make.id != 16328));
            if (id) {
                Cronospaces.Get(id)
                    .then(res => {
                        const { cronospace } = res.data;
                        const typeId = VEHICLE_TYPES.find(t => t.key == cronospace.type).id;
                        setType(typeId)
                        const sMake = makes.find(m => m.name == cronospace.manufacturer);
                        if (sMake) {
                            const data = {
                                makeId: sMake.id,
                                modelId: sMake.models.find(m => m.name == cronospace.model).id
                            }
                            setMake(data.makeId)
                            setModels(sMake.models)
                            setModel(data.modelId)
                        } else {
                            setMake("CUSTOM_MAKE")
                            setCustomMake(cronospace.manufacturer)
                            setCustomModel(cronospace.model)
                        }
                        setPlate(cronospace.plate)
                        setMore(cronospace.other || "")
                        if (cronospace.image_url)
                            setPics([...pics, cronospace.image_url])
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            } else {
                setIsLoading(false);
            }
        })
    }, [])

    const UploadPhoto = () => {
        const inputFile = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = async e => {
            const files = e.target.files;
            const file = files[0];
            if (file) {
                // const src = URL.createObjectURL(files[0]);
                // const reader = new FileReader();
                // reader.onload = () => {
                //     console.log(reader.result)
                //     setPics([...pics, reader.result]);
                // }
                // reader.readAsDataURL(file);
                try {
                    // const res = await Upload.Put(file);
                    setPics([...pics, file]);
                    // console.log(res.data.url);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        inputFile.click();
    }

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    const removePic = (indexToRemove) => {
        setPics(pics.filter((_, index) => index !== indexToRemove))
    }

    const onTypeChange = (e) => {
        setType(e.currentTarget.value);
        setMake("");
        setModel("");
    }

    const onMakeChange = (e) => {
        setMakeError("");
        setMake(e.currentTarget.value)
        if (e.currentTarget.value == "CUSTOM_MAKE") {

        } else {
            const selectedMake = makes.find(_make => _make.id == e.currentTarget.value);
            if (selectedMake)
                setModels(selectedMake.models)
        }
        setModel("");
    }

    const handleSubmit = async () => {
        if (make === "CUSTOM_MAKE") {
            if (customMake === "") {
                setCustomMakeError(Ts("Inserisci la marca"));
                return;
            }
            if (customModel === "") {
                setCustomModelError(Ts("Inserisci il modello"));
                return;
            }
        } else {
            if (make === "") {
                setMakeError(Ts("Seleziona una marca"));
                return;
            }
            if (model === "") {
                setModelError(Ts("Seleziona un modello"));
                return;
            }
        }
        if (plate === "") {
            setPlateError(Ts("Inserisci il numero di targa"));
            return;
        }
        if (type === "") {
            setTypeError(Ts("Seleziona la tipologia di veicolo"));
            return;
        }

        try {
            setIsSubmitting(true);
            const images = [];
            for (const file of pics) {
                if (file instanceof File) {
                    const res = await Upload.Put(file);
                    images.push(res.data.url)
                } else {
                    images.push(file)
                }
            }
            const makeName = make === "CUSTOM_MAKE" ? customMake : makes.find(m => m.id == make).name;
            const typeKEY = VEHICLE_TYPES.find(t => t.id == type).key;
            const modelName = make === "CUSTOM_MAKE" ? customModel : makes.find(m => m.id == make).models.find(m => m.id == model).name;
            await Cronospaces.Create(id, makeName, typeKEY, modelName, plate, images[0], more, customerId)
            if (customerId)
                navigate("/workshop/customers")
            else
                navigate("/garage")
        } catch (error) {
            addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR)
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title={Ts("Aggiungi Cronospace")} />
            <section>
                {isLoading && <LoaderSmall />}
                <div className="box-shadow">
                    {/* <div className="form-row">
                        <InputField error={modelError} type="text" label={Ts("Modello")} isRequired={true} value={model} onChange={(e) => { handleInputChange(e.target.value, setModel, setModelError) }} />
                    </div> */}
                    <div className="form-row">
                        <div className="cc-input-field select-field">
                            <label htmlFor="TYPE">{Ts("Tipologia")} *</label>
                            <select value={type} id="TYPE" data-validate="required" required="" onChange={onTypeChange} data-filled="true">
                                {VEHICLE_TYPES.map(t => {
                                    return <option key={t.id} value={t.id}>{t.name}</option>
                                })}
                            </select>
                            <div className="error" style={{ visibility: typeError === "" ? "hidden" : "visible" }}>{typeError}</div>
                        </div>
                    </div>
                    {/* <div className="form-row">
                        <InputField error={makeError} type="text" label={Ts("Marca")} isRequired={true} value={make} onChange={(e) => { handleInputChange(e.target.value, setMake, setMakeError) }} />
                    </div> */}
                    <div className="form-row">
                        <div className="cc-input-field select-field">
                            <label htmlFor="MAKE">{Ts("Marca")} *</label>
                            <select value={make} id="MAKE" data-validate="required" required="" onChange={onMakeChange} data-filled="true">
                                <option>{Ts("Seleziona marca")}</option>
                                {makes.filter(m => m.vehicleTypes.includes(type)).sort((m1, m2) => {
                                    if (m1.name < m2.name)
                                        return -1;
                                    if (m1.name > m2.name)
                                        return 1;
                                    return 0;
                                }).map(m => {
                                    return <option key={m.id} value={m.id}>{m.name}</option>
                                })}
                                <option value="CUSTOM_MAKE">{Ts("Altro")}</option>
                            </select>
                            <div className="error" style={{ visibility: makeError === "" ? "hidden" : "visible" }}>{makeError}</div>
                        </div>
                    </div>
                    {make == "CUSTOM_MAKE" && <>
                        <div className="form-row">
                            <InputField error={customMakeError} type="text" label={Ts("Marca")} isRequired={true} value={customMake} onChange={(e) => { handleInputChange(e.target.value, setCustomMake, setCustomMakeError) }} />
                        </div>
                        <div className="form-row">
                            <InputField error={customModelError} type="text" label={Ts("Modello")} isRequired={true} value={customModel} onChange={(e) => { handleInputChange(e.target.value, setCustomModel, setCustomModelError) }} />
                        </div>
                    </>}
                    {make != "CUSTOM_MAKE" && <div className="form-row">
                        <div className="cc-input-field select-field">
                            <label htmlFor="MODEL">{Ts("Modello")} *</label>
                            <select value={model} id="MODEL" data-validate="required" required="" onChange={(e) => { setModelError(""); setModel(e.currentTarget.value) }} data-filled="true" disabled={models.length == 0}>
                                <option>{Ts("Seleziona modello")}</option>
                                {models.map(m => {
                                    return <option key={m.id} value={m.id}>{m.name}</option>
                                })}
                            </select>
                            <div className="error" style={{ visibility: modelError === "" ? "hidden" : "visible" }}>{modelError}</div>
                        </div>
                    </div>}
                    <div className="form-row">
                        <InputField error={plateError} type="text" label={Ts("Targa")} isRequired={true} value={plate} onChange={(e) => { handleInputChange(e.target.value, setPlate, setPlateError) }} />
                    </div>
                    {/* <div className="form-row">
                        <InputField error={typeError} type="text" label={Ts("Tipologia")} isRequired={true} value={type} onChange={(e) => { handleInputChange(e.target.value, setType, setTypeError) }} />
                    </div> */}
                    <div className="form-row">
                        <InputField type="text" label={Ts("Altro")} value={more} onChange={(e) => { handleInputChange(e.target.value, setMore) }} />
                    </div>
                    <div className="form-row">
                        <div className="uploader">
                            <div className="thumbnails">
                                {pics.map((file, index) => {
                                    const src = file instanceof File ? URL.createObjectURL(file) : file;
                                    return (
                                        <div key={index} className="thumbnail">
                                            <img src={src} alt="" />
                                            <span onClick={() => removePic(index)}>X</span>
                                        </div>
                                    )
                                })}
                            </div>
                            {pics.length == 0 && <Button onClick={() => { UploadPhoto() }} label={<><SvgIcon id="add-photo" /> {Ts("Carica foto")}</>} type={TYPES.OUTLINE} size={SIZES.SMALL} />}
                        </div>
                    </div>
                    <div className="form-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '25px' }}>
                        <Button onClick={() => { navigate(-1) }} label={Ts("Annulla")} type={TYPES.OUTLINE} size={SIZES.BIG} />
                        <Button onClick={() => { handleSubmit() }} label={Ts("Salva")} size={SIZES.BIG} status={isSubmitting ? STATUS.WAITING : undefined} />
                    </div>
                </div>
            </section>
        </Layout>
    )

}

export default CreateCronospacePage;