const LoaderSmall = () => {

    return (
        <div className="loading-simple">
            <span className="loader-rotation"></span>
        </div>
    )

}

export default LoaderSmall;