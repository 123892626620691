import "../styles/password-recovery-page.scss"
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth, PasswordRecovery } from "../libs/js/api";
import InputField from "../components/InputField";
import Button, { LENGTHS, SIZES, STATUS, TYPES } from "../components/Button";
import Layout from "./Layout";
import { Ts } from "../libs/js/utils";
import { useNotification } from "../components/NotificationContext";
import { NOTIFICATION_TYPES } from "../components/Notification";

const PasswordRecoveryPage = () => {

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({ email: '' });
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: ''
        }))
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter')
            handlePasswordRecovery();
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { email: '' };

        if (!email.trim()) {
            newErrors.email = Ts("Inserisci la e-mail");
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = Ts('E-mail non valida');
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handlePasswordRecovery = async () => {
        if (validateForm()) {
            try {
                setIsLoading(true);
                await Auth.PasswordRecovery(email);
                setIsSubmitted(true);
            } catch (error) {
                if (error?.response?.status == 404) {
                    addNotification(Ts("Account non trovato"), NOTIFICATION_TYPES.ERROR)
                } else {
                    addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR)
                }
            }
            setIsLoading(false);
        }

    };

    if (isSubmitted)
        return (
            <Layout>
                <section>
                    <div className="box-shadow brd-rounded">
                        <div className="goal-message">
                            <div className="big-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                            </div>
                            <p className="text-center">
                                {Ts("Ti abbiamo inviato una e-mail per procedere al recupero della password")}
                            </p>
                        </div>
                    </div>

                    <div className="text-center mb-3">
                        <Link to="/login" className="plain-link small">{Ts("Accedi")}</Link>
                    </div>
                </section>
            </Layout>
        )

    return (
        <Layout>
            <section>
                <div className="box-shadow brd-rounded">
                    <div className="form-row mt-2">
                        <InputField id="INPUT-EMAIL" type="email" value={email} label={Ts("E-mail")} placeholder={Ts("Inserisci la e-mail")} onChange={handleEmailChange} onKeyUp={handleKeyUp} error={errors.email} />
                    </div>

                    <div className="button-set mt-0">
                        <Button label={Ts("Recupera Password")} onClick={handlePasswordRecovery} length={LENGTHS.FULLWIDTH} size={SIZES.BIG} status={isLoading && STATUS.WAITING} />
                    </div>
                </div>

                <div className="text-center mb-3">
                    <Link to="/login" className="plain-link small">{Ts("Accedi")}</Link>
                </div>

                <div className="text-center mt-5">
                    {Ts("Non hai un account")}
                    <div className="button-set mt-2">
                        <Button label={Ts("REGISTRATI")} onClick={() => { navigate('/register') }} size={SIZES.BIG} length={LENGTHS.FULLWIDTH} type={TYPES.OUTLINE} />
                    </div>

                </div>

            </section>
        </Layout>
    );
};

export default PasswordRecoveryPage;