import { useRef, useState } from "react";
import SvgIcon from "./SvgIcon";

const InputField = ({ id, type, value, label, placeholder, onChange, onKeyUp, error, isRequired, isChecked, isDisabled, maxLength }) => {
    const [length, setLength] = useState(0);
    let className = "cc-input-field";
    if (error)
        className += " error";
    if (isRequired)
        label += " *"

    const inputEl = useRef(null);
    const eyeOffEl = useRef(null);
    const eyeOnEl = useRef(null);

    const OnChange = (e) => {
        setLength(e.currentTarget.value.length);
        onChange(e);
    }

    return (
        <>
            <div className={className}>
                {maxLength && <div className={`char-counter ${length > maxLength ? "overflow" : ""}`}>{length}/{maxLength}</div>}
                {type === 'textarea' ?
                    <textarea onChange={OnChange} onKeyUp={onKeyUp} id={id} length="235" maxLength={maxLength || 535} placeholder={placeholder} value={value} disabled={isDisabled} /> :
                    <input ref={inputEl} onChange={OnChange} value={value} id={id} type={type} placeholder={placeholder} onKeyUp={onKeyUp} checked={isChecked} disabled={isDisabled} />
                }
                <label htmlFor={id}>{label}</label>
                <div className="error" style={{ visibility: error === "" ? "hidden" : "visible" }}>{error}</div>
            </div>
            {type == 'password' && <>
                <span ref={eyeOffEl} className="input-view-password-off" onClick={() => {
                    eyeOffEl.current.style.display = 'none';
                    eyeOnEl.current.style.display = 'block';
                    inputEl.current.setAttribute('type', 'text')
                }}>
                    <SvgIcon id="eye-off" />
                </span>
                <span style={{
                    display: 'none'
                }} ref={eyeOnEl} className="input-view-password-on" onClick={() => {
                    eyeOffEl.current.style.display = 'block';
                    eyeOnEl.current.style.display = 'none';
                    inputEl.current.setAttribute('type', 'password')
                }}>
                    <SvgIcon id="eye-on" />
                </span>
            </>}
        </>
    )
};

export default InputField;