import { useEffect, useState } from "react";
import Button, { SIZES, STATUS, TYPES } from "../components/Button";
import CronospaceSnippet from "../components/CronospaceSnippet";
import SectionHeader from "../components/SectionHeader";
import { Cronospaces } from "../libs/js/api";
import { useNavigate } from "react-router-dom";
import LoaderSmall from "../components/LoaderSmall";
import Layout from "./Layout";
import placeholderImage from "../images/placeholder-no-results.png";
import SvgIcon from "../components/SvgIcon";
import { Ts } from "../libs/js/utils";
import List from '../components/List';

const GaragePage = () => {
    const navigate = useNavigate();
    const [isEmpty, setIsEmpty] = useState(null);

    return (
        <Layout showHeader={false}>
            <SectionHeader title={Ts("Il mio Garage")} />
            {isEmpty == false && <div className="button-set fixed-top">
                <div>
                    <Button onClick={() => { navigate("/cronospace/create") }} type={TYPES.SOLID} size={SIZES.SMALL} label={<><SvgIcon id="add-car" /> {Ts("")}</>} />
                </div>
            </div>}
            <section>
                <div className="cronospace-wrapper">
                    <List
                        api={(page, pageSize) => { return Cronospaces.GetList(page, pageSize) }}
                        emptyPlaceholder={
                            <div className="empty-results-placeholder fixed">
                                <div className="box-shadow brd-rounded">
                                    <img src={placeholderImage} alt="" />
                                    {/* <h3></h3> */}
                                    <p>{Ts("Non ci sono veicoli nel tuo Garage")}</p>
                                    <Button onClick={() => { navigate("/cronospace/create") }} label={Ts("AGGIUNGI CRONOSPACE")} size={SIZES.BIG} />
                                </div>
                            </div>
                        }
                        format={cronospace => <CronospaceSnippet type={cronospace.type} id={cronospace.id} onClick={() => { navigate(`/cronospace/${cronospace.id}`) }} image_url={cronospace.image_url} key={cronospace.id} make={cronospace.manufacturer} model={cronospace.model} plate={cronospace.plate} />}
                        onLoad={(data) => {
                            const hasItems = data.length > 0;
                            console.log(data, hasItems);
                            setIsEmpty(!hasItems);
                        }}
                    />
                </div>
            </section>

        </Layout>
    )

}

export default GaragePage;