import '../styles/home-page.scss';

import { IsLoggedIn, Ts } from '../libs/js/utils';
import { useNavigate } from 'react-router-dom';
import Button, { SIZES } from '../components/Button';
import { useUserContext } from '../components/UserContext';
import PostPreview from '../components/PostPreview';
import { useEffect, useState } from 'react';
import { Posts } from '../libs/js/api';
import LoaderSmall from '../components/LoaderSmall';
import Layout from './Layout';
import List from '../components/List';
import { useBrandContext } from '../components/BrandContext';
import SectionHeader from '../components/SectionHeader';

const NewsPage = () => {
  const { user } = useUserContext();
  const { brand } = useBrandContext();
  // const [postsList, setPostsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // setIsLoading(true);
    // Posts.GetList().then(res => setPostsList(res.data.elements)).finally(() => setIsLoading(false))
  }, [])

  return (

    <Layout showHeader={false}>
      <SectionHeader title={Ts("NOTIZIE DA CRONOCAR")} />
      <section>
        {user && <div>{Ts("Ciao {0}, ecco le ultime notizie per te:").replace("{0}", user.name || user.display_name)}</div>}
        {/* <!-- SEARCHBOX BAR --> */}
        {/* <div className="search-box box-shadow brd-rounded">
          <input type="search" placeholder="search here ..." />
          <svg className="svg-icon search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" /></svg>
        </div> */}
        {/* <!-- SEARCHBOX BAR --> */}

        <div className="pt-3"></div>

        {!brand && <LoaderSmall />}
        {brand && <List api={(page, pageSize) => { return Posts.GetList(page, pageSize) }} format={post => <PostPreview onPress={() => { navigate(`/post/${post.uuid}`) }} key={post.uuid} date={post.published} title={post.title} text={post.content} image={post.image_url} />} emptyPlaceholder={<div className='text-center'>{Ts("Ancora nessun post pubblicato")}</div>} />}

      </section>

      {/* {IsLoggedIn() ? null : (
        <div className="button-set fixed-bottom">
          <Button label={Ts("ACCEDI O REGISTRATI")} onClick={() => { navigate('/login') }} size={SIZES.BIG} />
        </div>
      )} */}
    </Layout>

  );
};

export default NewsPage;