import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MarkdownView from "react-showdown";
import showdownToc from "showdown-toc";

import SectionHeader from "../components/SectionHeader";
import { Ts } from "../libs/js/utils";
import Layout from "./Layout";

const LegalPage = () => {
    const { doc } = useParams();
    const [mdText, setMdText] = useState("");

    const title = {
        cookies: Ts("Informativa cookie"),
        terms: Ts("Termini e condizioni"),
        privacy: Ts("Informativa privacy"),
    }[doc];

    useEffect(() => {
        import(`../assets/legal/it/${doc}.md`).then(async (file) => {
            const res = await fetch(file.default);
            const text = await res.text();
            setMdText(text);
        });
    }, [doc]);

    return (
        <Layout showHeader={false}>
            <SectionHeader title={title} />
            <section className="legal-page">
                <div className="box-shadow brd-rounded">
                    <MarkdownView
                        markdown={mdText}
                        options={{ tables: true, extensions: [showdownToc()] }} />
                </div>
            </section>
        </Layout>
    );
};

export default LegalPage;
