import React from "react"

export const SIZES = {
    SMALL: "SMALL",
    NORMAL: "NORMAL",
    BIG: "BIG"
}

export const LENGTHS = {
    AUTO: "AUTO",
    FULLWIDTH: "FULL-WIDTH"
}

export const TYPES = {
    OUTLINE: "OUTLINE",
    ALT: "ALT"
}

export const STATUS = {
    WAITING: "WAITING"
}

const Button = ({ label, onClick, type, length, size, status, disabled }) => {
    let classNames = ["btn-button"]

    if (size === SIZES.SMALL)
        classNames.push("small");
    if (size === SIZES.BIG)
        classNames.push("big");

    if (length === LENGTHS.FULLWIDTH)
        classNames.push("full-width");

    if (type === TYPES.OUTLINE)
        classNames.push("outline");
    if (type === TYPES.ALT)
        classNames.push("alternate");

    if (status === STATUS.WAITING)
        classNames.push("btn-loading");

    return (
        <button onClick={onClick} className={classNames.join(" ")} disabled={disabled}>
            {
                status === STATUS.WAITING ? <>
                    <span></span>
                    <span></span>
                    <span></span>
                </> :
                    React.isValidElement(label) ?
                        label :
                        <span>{label}</span>
            }
        </button>
    )
};

export default Button;