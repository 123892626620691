import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import List from "../components/List";
import SectionHeader from "../components/SectionHeader";
import { Cronos } from "../libs/js/api";
import { FormatDate, Ts } from "../libs/js/utils";
import Layout from "./Layout";
import "./CronoStory.scss";
import LoaderSmall from "../components/LoaderSmall";
import SvgIcon from "../components/SvgIcon";
import { CRONO_TYPES } from "../libs/js/config";

const CronoStoryPage = () => {
    const { cid } = useParams();
    const [crono, setCrono] = useState({
        crono: {}
    })

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        Cronos.Get(cid).then(res => {
            setCrono(res.data);
            setIsLoading(false);
        })
    }, [])

    const Format = (item, onDelete, index) => {
        let label = Ts("Modifica: ");
        if (item.datetime === item.created_at)
            label = Ts("Creazione: ");
        if (index == 0)
            label = Ts("Versione attuale: ");
        return (
            <div key={index} className="story-item">
                <div><b>{label} {FormatDate(item.datetime)}</b></div>
                {item.description}
            </div>
        )
    }

    const cronoType = CRONO_TYPES.find(ct => ct.value == crono.crono.crono_type_id);

    return (
        <>
            <Layout showHeader={false}>
                <SectionHeader title={Ts("Storico") + " - " + cronoType?.text} />
                <section>
                    {isLoading && <LoaderSmall />}
                    <div className="box-shadow">
                        <div className="heading">
                            <div className="icon-menu">
                                <SvgIcon id={cronoType?.iconId} />
                            </div>
                            <div className="copy-menu">
                                <strong>{cronoType?.text}</strong>
                            </div>
                        </div>
                        <List api={(page, page_size) => { return Cronos.GetHistory(cid, page, page_size) }} format={Format} />
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default CronoStoryPage;