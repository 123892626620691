import axios from "axios";

console.log(process.env.NODE_ENV)

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
const basePath = "/api/v1";

export const Auth = {
    Login: (email, password) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/login`, {
            email: email,
            password: password
        })
    },
    Register: (type, name, email, password) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/register`, {
            type: type,
            display_name: name,
            // surname: surname,
            // vat_id: vat,
            email: email,
            password: password
        })
    },
    PasswordRecovery: (email) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/reset_password`, {
            email: email
        })
    },
    PasswordReset: (token, password) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/redeem_token`, {
            token,
            password
        })
    },
    RegisterWorkshop: (type, name, email, password) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/register/garage`, {
            type: type,
            business_name: name,
            // surname: surname,
            // vat_number: vat,
            email: email,
            password: password
        })
    },
    ValidateEmail: (token) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/validate_email`, {
            token
        })
    },
    ReSendWelcomeEmail: (email) => {
        return axios.post(`${API_DOMAIN}${basePath}/auth/resend_validate_email`, {
            email
        })
    }
}

export const Workshop = {
    GetCustomers: (page = 0, page_size = 15) => {
        return axios.post(`${API_DOMAIN}${basePath}/garage/users`, {
            page,
            page_size
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    AddCustomer: (name, surname, email, image_url, phone) => {
        return axios.post(`${API_DOMAIN}${basePath}/garage/users/new`, {
            name,
            surname,
            email,
            image_url,
            phone
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Save: (business_name, owner_name, business_email, display_name, logo_url, logo_min_url, theme_id, phone, vat_number) => {
        return axios.post(`${API_DOMAIN}${basePath}/garage/data`, {
            business_name,
            owner_name,
            display_name,
            logo_url,
            logo_min_url,
            theme_id,
            phone,
            vat_number,
            business_email
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    }
}

export const User = {
    GetUserData: () => {
        return axios.get(`${API_DOMAIN}${basePath}/user/personal_data`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    SetUserData: (name, surname, display_name, display_info) => {
        return axios.post(`${API_DOMAIN}${basePath}/user/personal_data`, {
            name,
            surname,
            display_name,
            display_info
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    GetBillingAddress: () => {
        return axios.get(`${API_DOMAIN}${basePath}/user/billing_address`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    SaveBillingAddress: (firstName, lastName, country, address, postalCode, province, city, phone) => {
        return axios.post(`${API_DOMAIN}${basePath}/user/billing_address/save`, {
            name: firstName,
            surname: lastName,
            country_iso_code: country,
            address: address,
            zip_code: postalCode,
            province: province,
            city: city,
            phone: phone
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    }
}

export const Posts = {
    GetList: (page = 0, page_size = 15, brand_uuid = 0) => {
        return axios.post(`${API_DOMAIN}${basePath}/posts`, {
            page,
            page_size,
            brand_uuid
        })
    },

    GetPost: (uuid) => {
        return axios.post(`${API_DOMAIN}${basePath}/posts/get`, {
            id: uuid
        })
    }
}

export const Main = {
    Init: () => {
        const authToken = localStorage.getItem('authToken');
        return axios.post(`${API_DOMAIN}${basePath}/init`, {
            token: authToken
        })
    },
    GetBrand: (brand_uuid = 0) => {
        return axios.post(`${API_DOMAIN}${basePath}/brand`, {
            id: brand_uuid
        })
    }
}

export const Cronospaces = {
    GetModels: () => {
        return axios.get(`https://listing-creation.api.autoscout24.com/makes?culture=it-IT`)
    },
    GetList: (page = 0, page_size = 15, user_id) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronospaces`, {
            page,
            page_size,
            user_id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Get: (uuid) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronospaces/get`, {
            id: uuid
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Create: (id, manufacturer, type, model, plate, image_url, other, user_id) => {
        let endpoint = id ? 'update' : 'new';
        return axios.post(`${API_DOMAIN}${basePath}/cronospaces/${endpoint}`, {
            id,
            manufacturer,
            type,
            model,
            plate,
            image_url,
            other,
            user_id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Delete: (uuid) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronospaces/delete`, {
            id: uuid
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    }
}

export const Upload = {
    Put: file => {
        return axios.post(`${API_DOMAIN}${basePath}/media/upload/${file.name}`, file, {
            headers: {
                'Content-Type': file.type,
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    }
}

export const Cronos = {
    GetList: (cronospace_id, page = 0, page_size = 15) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos`, {
            cronospace_id,
            page,
            page_size
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Get: (id) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos/get`, {
            id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    GetHistory: (id, page, page_size) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos/history`, {
            id,
            page,
            page_size
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    GetTypes: (page, page_size) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos/types`, {
            page,
            page_size
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Create: (title, description, cronospace_id, crono_id, crono_type_id) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos/new`, {
            title,
            description,
            cronospace_id,
            crono_type_id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Update: (title, description, cronospace_id, crono_id, crono_type_id) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos/update`, {
            title,
            description,
            cronospace_id,
            crono_type_id,
            id: crono_id
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    },
    Delete: (uuid) => {
        return axios.post(`${API_DOMAIN}${basePath}/cronos/delete`, {
            id: uuid
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        })
    }
}