import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "../libs/js/api";
import InputField from "../components/InputField";
import Button, { LENGTHS, SIZES, STATUS } from "../components/Button";
import Layout from "./Layout";
import { NOTIFICATION_TYPES } from "../components/Notification";
import { useNotification } from "../components/NotificationContext";
import { Ts, VatNumberCheck, CheckPasswordStrength } from "../libs/js/utils";
import SvgIcon from "../components/SvgIcon";
import { useUserContext } from "../components/UserContext";

const RegisterPage = ({ onRegister }) => {
    const { setUser, setWorkshop } = useUserContext();
    const [name, setName] = useState('');
    // const [surname, setSurname] = useState('');
    const [vat, setVat] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [type, setType] = useState('user');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        // surname: '',
        email: '',
        password: '',
        passwordConfirm: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();

    const { addNotification } = useNotification();

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            name: ''
        }))
    };

    // const handleSurnameChange = (e) => {
    //     setSurname(e.target.value);
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         surname: ''
    //     }))
    // };

    const handleVatChange = (e) => {
        setVat(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            vat: ''
        }))
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: ''
        }))
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            password: ''
        }))
    };

    const handlePasswordConfirmChange = (e) => {
        setPasswordConfirm(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            passwordConfirm: ''
        }))
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter')
            handleRegister();
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            name: '',
            // surname: '', 
            email: '',
            password: '',
            passwordConfirm: ''
        };

        if (!name.trim()) {
            newErrors.name = Ts("Inserisci il nome");
            valid = false;
        }

        // if (type === 'user')
        //     if (!surname.trim()) {
        //         newErrors.surname = Ts("Inserisci il cognome");
        //         valid = false;
        //     }

        // if (type === 'company') {
        //     if (!vat.trim()) {
        //         newErrors.vat = Ts("Inserisci il numero di partita IVA");
        //         valid = false;
        //     } else if (!VatNumberCheck(vat)) {
        //         newErrors.vat = Ts("Numero di partita IVA non valido");
        //         valid = false;
        //     }
        // }

        if (!email.trim()) {
            newErrors.email = Ts("Inserisci la e-mail");
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = Ts('E-mail non valida');
            valid = false;
        }

        // const passwordStrength = CheckPasswordStrength(password);
        // console.log(passwordStrength);
        if (!password.trim()) {
            newErrors.password = Ts("Inserisci la password");
            valid = false;
        } else if (password.length < 8) {
            newErrors.password = Ts("La password deve avere almeno 8 caratteri");
            valid = false;
        } else if (!/[A-Z]/.test(password)) {
            newErrors.password = Ts("La password deve avere almeno una lettera maiuscola");
            valid = false;
        } else if (!/[a-z]/.test(password)) {
            newErrors.password = Ts("La password deve avere almeno una lettera minuscola");
            valid = false;
        } else if (!/[\d]/.test(password)) {
            newErrors.password = Ts("La password deve avere almeno un numero");
            valid = false;
        } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-]/.test(password)) {
            newErrors.password = Ts("La password deve avere almeno uno tra i caratteri !@#$%^&*()_+{}\[\]:;<>,.?~\\/\-");
            valid = false;
        }

        if (!passwordConfirm.trim()) {
            newErrors.passwordConfirm = Ts("Re-inserisci la password");
            valid = false;
        } else if (password != passwordConfirm) {
            newErrors.passwordConfirm = Ts("La password non è uguale");
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const api = type == 'company' ? Auth.RegisterWorkshop : Auth.Register;

    const handleRegister = async () => {
        if (validateForm()) {
            try {
                setIsLoading(true);
                // const res = await api(type, name, surname, vat, email, password);
                const res = await api(type, name, email, password);
                // setUser(res.data.user_data)
                // setWorkshop(res.data.garage_data)
                // onRegister();
                // localStorage.setItem('authToken', res.data.auth_token)
                setIsSubmitted(true);
            } catch (error) {
                console.error(error);
                if (error?.response?.status == 409) {
                    addNotification(Ts("E-mail già utilizzata"), NOTIFICATION_TYPES.ERROR)
                } else {
                    addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR)
                }
            }
            setIsLoading(false);
        }

    };

    return (
        <Layout>
            <section>
                {isSubmitted ? <>
                    <div className="box-shadow brd-rounded">
                        <div className="goal-message">
                            <div className="big-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                            </div>
                            <p className="text-center">
                                {Ts("Ti abbiamo inviato una e-mail per la verifica dell'indirizzo di posta da te inserito")}
                            </p>
                        </div>
                    </div>
                </> : <>
                    <div className="box-shadow brd-rounded">
                        <h2 className="title-1 text-center">{Ts("Registrati")}</h2>
                        <div className="form-row mt-2">
                            <div className="cc-input-field">
                                <div className="options">
                                    <div>
                                        <input name="type" checked={type === 'user'} type="radio" id="PRIVATE-USER" value="user" onChange={handleTypeChange} />
                                        <label htmlFor="PRIVATE-USER">{Ts("Privato")}</label>
                                    </div>
                                    <div>
                                        <input name="type" checked={type === 'company'} type="radio" id="GARAGE" value="company" onChange={handleTypeChange} />
                                        <label htmlFor="GARAGE">{Ts("Officina")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <InputField id="INPUT-NAME" type="text" value={name} label={type === 'company' ? Ts("Ragione Sociale") : Ts("Nome o nickname")} onChange={handleNameChange} onKeyUp={handleKeyUp} error={errors.name} />
                        </div>
                        {/* {type === 'user' ? (
                            <div className="form-row">
                                <InputField id="INPUT-SURNAME" type="text" value={surname} label={Ts("Cognome")} onChange={handleSurnameChange} onKeyUp={handleKeyUp} error={errors.surname} />
                            </div>
                        ) : (
                            <div className="form-row">
                                <InputField id="VAT-ID" type="text" value={vat} label={Ts("Partita IVA")} onChange={handleVatChange} onKeyUp={handleKeyUp} error={errors.vat} />
                            </div>
                        )} */}
                        <div className="form-row">
                            <InputField id="INPUT-EMAIL" type="email" value={email} label={Ts("E-mail")} onChange={handleEmailChange} onKeyUp={handleKeyUp} error={errors.email} />
                        </div>
                        <div className="form-row password-off">
                            <InputField id="INPUT-PASSWORD" type="password" value={password} label={
                                <>
                                    {Ts("Password")}
                                    <div className="tooltip-help">
                                        <div>{`${Ts("Almeno 8 caratteri")}`}</div>
                                        <div>{`${Ts("Almeno una lettera maiuscola")}`}</div>
                                        <div>{`${Ts("Almeno una lettera minuscola")}`}</div>
                                        <div>{`${Ts("Almeno un numero")}`}</div>
                                        <div>{`${Ts("Almeno uno tra i seguenti caratteri:")} !@#$%^&*()_+{}\[\]:;<>,.?~\\/\-`}</div>
                                    </div>
                                </>
                            }
                                onChange={handlePasswordChange} onKeyUp={handleKeyUp} error={errors.password} />
                        </div>

                        <div className="form-row password-off">
                            <InputField id="INPUT-PASSWORD-CONFIRM" type="password" value={passwordConfirm} label={Ts("Conferma password")}
                                onChange={handlePasswordConfirmChange} onKeyUp={handleKeyUp} error={errors.passwordConfirm} />
                        </div>

                        <p className="text-center small-text mb-3">
                            {Ts("Riceverai una e-mail per la verifica dell'indirizzo di posta da te inserito")}
                        </p>

                        <div className="button-set mt-0">
                            <Button label={Ts("REGISTRATI")} onClick={handleRegister} size={SIZES.BIG} length={LENGTHS.FULLWIDTH} status={isLoading && STATUS.WAITING} />
                        </div>
                    </div>

                    <div className="text-center mb-3">
                        <Link to="/login" className="plain-link small">{Ts("Ho già un account")} &gt;&gt;</Link>
                    </div>
                </>}
            </section>
        </Layout>
    );
};

export default RegisterPage;