import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button, { SIZES, STATUS } from "../components/Button";
import InputField from "../components/InputField";
import { NOTIFICATION_TYPES } from "../components/Notification";
import { useNotification } from "../components/NotificationContext";
import SectionHeader from "../components/SectionHeader";
import { useUserContext } from "../components/UserContext";
import { SetUserData, User } from "../libs/js/api";
import { Ts } from "../libs/js/utils";
import Layout from "./Layout";

const AccountPage = () => {

    const { user, setUser, userEmail } = useUserContext();
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [publicName, setPublicName] = useState("");
    const [displayInfo, setDisplayInfo] = useState(false);
    const [email, setEmail] = useState("");
    const [nameError, setNameError] = useState("");
    const [surnameError, setSurnameError] = useState("")
    const [publicNameError, setPublicNameError] = useState("")
    const [emailError, setEmailError] = useState("")

    const { addNotification } = useNotification();

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (user) {
            setName(user.name || "");
            setSurname(user.surname || "");
            setPublicName(user.display_name || "");
            setEmail(userEmail || "")
            setDisplayInfo(user.display_info || false);
        }
    }, [user, userEmail]);

    const navigate = useNavigate();

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    const handleSubmit = async () => {
        if (name.trim() === "") {
            setNameError(Ts("Inserisci il tuo nome"))
            return;
        }
        if (surname.trim() === "") {
            setSurnameError(Ts("Inserisci il tuo cognome"))
            return;
        }
        if (publicName.trim() === "") {
            setPublicNameError(Ts("Inserisci il nome da visualizzare"))
            return;
        }
        // if (email.trim() === "") {
        //     setEmailError("Inserisci la tua e-mail")
        //     return;
        // }

        try {
            setIsSubmitting(true)
            const res = await User.SetUserData(name, surname, publicName, displayInfo)
            setUser(res.data);
            navigate('/profile')
        } catch (error) {
            addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title="Profilo - Gestione Account" />
            <section>
                <div className="box-shadow">
                    <p>{Ts("Impostazioni generali del tuo account.")}</p>
                    <div className="form-row">
                        <InputField label={Ts("Nome")} value={name} isRequired={true} error={nameError} onChange={e => handleInputChange(e.target.value, setName, setNameError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Cognome")} value={surname} isRequired={true} error={surnameError} onChange={e => handleInputChange(e.target.value, setSurname, setSurnameError)} />
                    </div>
                    <div className="form-row">
                        <InputField label={Ts("Nome Visualizzato")} value={publicName} isRequired={true} error={publicNameError} onChange={e => handleInputChange(e.target.value, setPublicName, setPublicNameError)} />
                    </div>
                    <div className="form-row">
                        <p>{Ts("Questa informazione verrà visualizzata in tutte le sezioni pubbliche del tuo account")}</p>
                        <InputField id="HIDE" type="checkbox" label={Ts("Nascondi questa info nella vista pubblica")} isChecked={!displayInfo} onChange={e => { setDisplayInfo(!e.target.checked) }} />
                    </div>
                    <div className="form-row">
                        <InputField type="email" value={email} label={Ts("E-mail")} isRequired={true} error={emailError} onChange={e => handleInputChange(e.target.value, setEmail, setEmailError)} isDisabled={true} />
                    </div>
                    {/* <div className="text-center">
                        <Button onClick={() => { handleSubmit() }} label="Salva Modifiche" status={isSubmitting && STATUS.WAITING} />
                    </div> */}
                </div>
            </section>
            <div className="button-set fixed-bottom">
                <Button label={Ts("Salva Modifiche")} onClick={() => { handleSubmit() }} size={SIZES.BIG} status={isSubmitting && STATUS.WAITING} />
            </div>
        </Layout>
    )

}
export default AccountPage;