import "./Footer.scss";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { IsLoggedIn, Ts } from "../libs/js/utils";
import SvgIcon from "./SvgIcon";
import { useUserContext } from "./UserContext";

const Footer = ({ CTA }) => {
    const isLoggedIn = IsLoggedIn();
    const navigate = useNavigate();
    const { workshop } = useUserContext();
    const isHomePage = useMatch("/");

    if (CTA) {
        return (
            <footer className="cta">
                <span className="center-icon" onClick={CTA.onClick}>{CTA.label}</span>
            </footer>
        )
    }

    if (isLoggedIn)
        return (
            <footer>
                <div className="footer-toolbar">
                    <span onClick={() => { navigate('/') }}>
                        <SvgIcon id="home" />
                    </span>
                    {workshop ?
                        <span onClick={() => { navigate('/workshop/customers') }} className={`customer center-icon${isHomePage ? ' big-icon' : ''}`}>
                            <SvgIcon id="customer" />
                            <span>{Ts("CLIENTI")}</span>
                        </span> :
                        <span onClick={() => { navigate('/garage') }} className={`garage center-icon${isHomePage ? ' big-icon' : ''}`}>
                            <SvgIcon id="garage" />
                            <span>{Ts("GARAGE")}</span>
                        </span>
                    }
                    <span onClick={() => { navigate('/profile') }}>
                        <SvgIcon id="user" />
                    </span>
                </div>
            </footer>
        )

    return (
        <footer className="extern-footer">
            <div className="footer-toolbar">
                <p>
                    <Link to="https://cronocar.tawk.help/" target="_blank">
                        <SvgIcon width={20} height={20} id="help" /> {Ts("Come funziona CronoCar?")}
                    </Link>
                </p>
                <p>
                    {Ts("© Crono Car V.1.0")}
                    &nbsp;-&nbsp;
                    <Link to="/legal/cookies">Cookie</Link>
                    {/* &nbsp;-&nbsp;
                    <Link to="/legal/terms">Terms</Link> */}
                    &nbsp;-&nbsp;
                    <Link to="/legal/privacy">Privacy</Link>
                </p>
            </div>
        </footer>
    )
};

export default Footer;