import "./UICommons.scss";
import SvgIcon from "../components/SvgIcon";
import Layout from "./Layout";
import { useEffect, useState } from "react";
import LoaderSmall from "../components/LoaderSmall";

const UICommonsPage = ({ }) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }, [])

    const svgIcons = Array.from(document.querySelectorAll('#ICONS-MAP symbol'));

    return (
        <>
            <Layout>
                <section>
                    {isLoading && <LoaderSmall />}
                    <div className="box-shadow">
                        <p>
                            Usage: <span style={{
                                fontFamily: 'monospace'
                            }}>
                                &lt;SvgIcon id="home" /&gt;
                            </span>
                            <br />
                            Result: <SvgIcon id="home" />
                        </p>
                        <div className="svg--icons">
                            {svgIcons.map((icon, i) => {
                                const iconId = icon.getAttribute('id').replace('icon-', '')
                                return (
                                    <div key={i} className="svg--icon">
                                        <SvgIcon id={iconId} />
                                        <span>{iconId}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}
export default UICommonsPage;