import MobileAppInterface, { Deferred, InvokeAction } from "./mobile-app-interface";

function Success() {
    if (window.APP_DEBUG == true) {
        return
    }
    var def = new Deferred()
    InvokeAction("success", { msg: "SUCCESS" }, def)
    return def.promise;
}

function Error() {
    if (window.APP_DEBUG == true) {
        return
    }
    var def = new Deferred()
    InvokeAction("error", { msg: "FAIL" }, def)
    return def.promise;
}

/**
 * 
 * @param {*} vPermissions ["android.permission.POST_NOTIFICATIONS"]
 * @returns 
 */
function RequestPermission(vPermissions) {
    if (window.APP_DEBUG == true) {
        return true
    }

    var def = new Deferred();
    InvokeAction("request_permission", vPermissions, def)
    return def.promise;
}

/**
 * 
 * @param {*} obj { key: value }
 * @returns 
 */
function SaveStorage(obj) {
    if (window.APP_DEBUG == true) {
        return true
    }

    var def = new Deferred();
    InvokeAction("save_storage", obj, def)
    return def.promise;
}

function GetStorage(vKeys) {
    if (window.APP_DEBUG == true) {
        return true
    }

    var def = new Deferred();
    InvokeAction("get_storage", vKeys, def)
    return def.promise;
}

function OpenBrowser(url) {
    if (window.APP_DEBUG == true) {
        return true
    }

    var def = new Deferred();
    InvokeAction("open_browser", url, def)
    return def.promise;
}

function NativeShare(text) {
    if (window.APP_DEBUG == true) {
        return true
    }

    var def = new Deferred()
    InvokeAction("native_share", text, def)
    return def.promise;
}

const MobileAppCallbacks = {
    Success,
    Error,
    RequestPermission,
    SaveStorage,
    GetStorage,
    OpenBrowser,
    NativeShare
};


export default MobileAppCallbacks;
window.MobileAppCallbacks = MobileAppCallbacks;