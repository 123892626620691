import { createContext, useContext, useState } from "react";

const BrandContext = createContext();

export function useBrandContext() {
    return useContext(BrandContext);
}

export function BrandContextProvider({ children }) {
    const [brand, setBrand] = useState(null);

    return (
        <BrandContext.Provider value={{ brand, setBrand }}>
            {children}
        </BrandContext.Provider>
    );
}