import React from "react";
import { useNotification } from "./NotificationContext";
import Notification from "./Notification";

const NotificationContainer = () => {
    const { notifications } = useNotification();

    return (
        <>
            {notifications.map((notification) => (
                <Notification key={notification.id} notification={notification} />
            ))}
        </>
    );
};

export default NotificationContainer;