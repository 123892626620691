import { useState } from "react";
import { Cronos } from "../libs/js/api";
import { FormatDate, Ts } from "../libs/js/utils";
import { NOTIFICATION_TYPES } from "./Notification";
import { useNotification } from "./NotificationContext";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "./SvgIcon";
import { CRONO_TYPES } from "../libs/js/config";
import Modal from "./Modal";
import LoaderSmall from "./LoaderSmall";

const CronoListItem = ({ crono, onDelete, canEdit }) => {
    const { id, cronospace_id, crono_type_id, title, datetime, description } = crono.crono;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const Delete = async () => {
        setIsLoading(true);
        try {
            await Cronos.Delete(id)
            onDelete();
        } catch (error) {
            console.error(error)
            addNotification(Ts("Si è verificato un errore"), NOTIFICATION_TYPES.ERROR);
        } finally {
            setIsLoading(false);
        }
    }

    const Edit = () => {
        navigate(`crono/${id}/edit`)
    }

    const Share = () => {
        navigator.share({
            title: crono_type_id == -1 ? title : cronoType.text,
            text: description,
            url: window.location.href
        })
    }

    const cronoType = CRONO_TYPES.find(ct => ct.value == crono_type_id);

    return (
        <>
            <li className={isCollapsed ? undefined : "uncollapsed"} style={{ position: 'relative ' }}>
                {isLoading && <LoaderSmall />}
                <div className="m-row" onClick={() => { setIsCollapsed(!isCollapsed) }}>
                    <div className="icon-menu">
                        <SvgIcon id={cronoType.iconId} />
                    </div>
                    <div className="copy-menu">
                        <strong>{crono_type_id == -1 ? title : cronoType.text}</strong>
                        <p className="small-text">{FormatDate(datetime)}</p>
                    </div>
                    <svg className="svg-icon arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                </div>
                <div className="extra-row">
                    <p>{description}</p>
                    {/* <p>Documento allegato</p> */}
                    {/* <a href="#">
                        FAttura 20012k.pdf
                        <SvgIcon id="download" />
                    </a> */}
                    <Link to={`/cronospace/${cronospace_id}/crono/${id}`}>{Ts("Visualizza storico")} ({crono.history_count} rev)<SvgIcon id="history" /></Link>
                    <div className="button-set">
                        {canEdit ? <button onClick={() => { setShowModal(true) }} className="btn-button small no-border">
                            <SvgIcon id="delete" />
                            <span>{Ts("Elimina")}</span>
                        </button> : null}
                        {canEdit ? <button onClick={() => { Edit() }} className="btn-button small no-border">
                            <SvgIcon id="edit" />
                            <span>{Ts("Modifica")}</span>
                        </button> : null}
                        <button onClick={() => { Share() }} className="btn-button small no-border">
                            <SvgIcon id="send" />
                            <span>{Ts("Invia Crono")}</span>
                        </button>
                    </div>
                </div>
            </li>
            <Modal onClose={() => { setShowModal(false) }} isOpen={showModal} onConfirm={() => { setShowModal(false); Delete() }} message={Ts("Sei sicuro di voler eliminare questo Crono?")} />
        </>
    )
}
export default CronoListItem;