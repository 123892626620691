import React, { createContext, useContext, useState } from "react";
import { NOTIFICATION_TYPES } from "./Notification";

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, type = NOTIFICATION_TYPES.WARNING) => {
        const newNotification = { message, type, id: Date.now() };
        setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
    };

    const removeNotification = (id) => {
        setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
    };

    const clearNotifications = () => {
        setNotifications([]);
    };

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                addNotification,
                removeNotification,
                clearNotifications,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};