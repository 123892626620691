import { createContext, useContext, useState } from "react";
import { IsLoggedIn } from "../libs/js/utils";

const UserContext = createContext();

export function useUserContext() {
    return useContext(UserContext);
}

export function UserContextProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(IsLoggedIn());
    const [isEmailValidated, setIsEmailValidated] = useState(null);
    const [user, setUser] = useState(null);
    const [userEmail, setUserEmail] = useState("");
    const [workshop, setWorkshop] = useState(null);

    return (
        <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser, userEmail, setUserEmail, isEmailValidated, setIsEmailValidated, workshop, setWorkshop }}>
            {children}
        </UserContext.Provider>
    );
}