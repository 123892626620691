import { FormatDate } from "../libs/js/utils";

const PostPreview = ({ title, text, date, image, onPress }) => {

    function GetText() {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.innerText;
    }

    return (
        <div className="post-snippet" onClick={onPress}>
            <img src={image} alt="" />
            <div>
                <span className="micro-text">{FormatDate(date)}</span>
                <h3 className="title-2">{title}</h3>
                <p className="small-text">
                    {GetText()}
                </p>
            </div>
        </div>
    )
}

export default PostPreview;