import { useEffect, useState } from "react";
import SvgIcon from "./SvgIcon";

function ContextMenu({ options = [] }) {
    const [isOpen, setIsOpen] = useState(false);

    const Hide = e => {
        console.log('Document Handler')
        setIsOpen(false)
    }

    useEffect(() => {
        if (options.length) {
            document.addEventListener("click", Hide)
            return () => { document.removeEventListener("click", Hide) }
        }
    }, [])

    return (
        <div className="options" onClick={(e) => { e.stopPropagation(); setIsOpen(!isOpen) }} style={{ visibility: options.length == 0 ? 'hidden' : undefined }}>
            <SvgIcon id="dots" />

            {/* popup menu */}

            <ul className="popup-menu" style={{ display: isOpen == false ? 'none' : undefined }}>
                {options.map((option, index) => <li className={option.hasSeparator ? "sep" : undefined} onClick={option.onClick} key={index}>{option.entry}</li>)}
            </ul>
            {/* popup menu */}
        </div>
    )
}

export default ContextMenu;