import Layout from "./Layout";
import SectionHeader from "../components/SectionHeader";
import { useParams, useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import { useEffect, useRef, useState } from "react";
import Button, { SIZES, STATUS, TYPES } from "../components/Button";
import { Cronos } from "../libs/js/api";
import { Ts } from "../libs/js/utils";
import LoaderSmall from "../components/LoaderSmall";
import ImageUploader from "../components/ImageUploader";
import SvgIcon from "../components/SvgIcon";

const CreatePostPage = () => {

    const { pid } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [text, setText] = useState("");
    const [textError, setTextError] = useState("");
    const [date, setDate] = useState("");
    const [dateError, setDateError] = useState("");
    const [image, setImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);

    const inputDate = useRef(null);

    useEffect(() => {
        const today = new Date();
        inputDate.current.setAttribute("min", `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`)
    }, [])

    const handleInputChange = (value, fnSetInputValue, fnSetInputError) => {
        fnSetInputValue(value);
        if (typeof fnSetInputError === 'function')
            fnSetInputError("");
    }

    const handleDateChange = (e) => {
        setDate(e.currentTarget.value)
    }

    const handleSubmit = () => {
        if (title.trim() == "") {
            setTitleError(Ts("Inserisci il titolo"))
            return;
        }
        if (title.trim() == "") {
            setTitleError(Ts("Inserisci il titolo"))
            return;
        }
    }

    const onImageUploaderChange = images => {
        const _imagesToUpload = images.filter(image => image instanceof File);
        setImagesToUpload(_imagesToUpload);
    }

    return (
        <Layout showHeader={false}>
            <SectionHeader title={pid ? Ts("Modifica post") : Ts("Crea post")} />
            <section>
                {isLoading && <LoaderSmall />}
                <div className="box-shadow brd-rounded">
                    <div className="form-row">
                        <InputField id="TITLE" value={title} label={Ts("Titolo")} placeholder={Ts("Aggiungi un titolo")} onChange={e => { handleInputChange(e.target.value, setTitle, setTitleError) }} error={titleError} isRequired={true} maxLength={150} />
                    </div>
                    <div className="form-row">
                        <InputField id="TEXT" type="textarea" value={text} label={Ts("Testo")} placeholder={Ts("Aggiungi del testo")} onChange={e => { handleInputChange(e.target.value, setText, setTextError) }} error={textError} isRequired={true} maxLength={5000} />
                    </div>
                    <div className="form-row">
                        <div className="cc-input-field">
                            <label htmlFor="EXPIRY_DATE">{Ts("Media (foto/video) *")}</label>
                            <ImageUploader maxImagesN={1} onChange={onImageUploaderChange} ctaLabel={<><SvgIcon id="add-photo" /> {Ts("Aggiungi immagine")}</>} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="cc-input-field">
                            <label htmlFor="EXPIRY_DATE">{Ts("Data di scadenza")} *</label>
                            <input ref={inputDate} id="EXPIRY_DATE" value={date} type="date" onChange={handleDateChange} />
                            <div className="error"></div>
                        </div>
                    </div>
                    <div className="form-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '25px' }}>
                        <Button onClick={() => { navigate(-1) }} label={Ts("Annulla")} type={TYPES.OUTLINE} size={SIZES.BIG} />
                        <Button onClick={() => { handleSubmit() }} label={Ts("Salva")} size={SIZES.BIG} status={isSubmitting ? STATUS.WAITING : undefined} />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default CreatePostPage;