import { useNavigate } from "react-router-dom";
import SvgIcon from "./SvgIcon";
import { useState, useEffect } from "react";
import ContextMenu from "./ContextMenu";
const SectionHeader = ({ title, options = [] }) => {
    const navigate = useNavigate();

    return (
        <header className="header-section">
            <div onClick={() => { navigate(-1) }} className="back">
                <SvgIcon id="back" />
            </div>

            <h1 className="title-1">{title}</h1>

            <ContextMenu options={options} />
        </header>
    )

}
export default SectionHeader;